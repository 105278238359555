import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  industryOptions,
  CompanyName,
  Industry,
  OtherIndustry,
} from './Common';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function SmallMediumBusiness() {
  const [selectedOption, setSelectedOption] = useState('');
  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <CompanyName />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Industry options={industryOptions} onSelect={setSelectedOption} />
        {selectedOption === 'Other' && <OtherIndustry />}
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <NumberOfEmployees />
      </div>
    </div>
  );
}

const NumberOfEmployees = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="number-of-employees"
              label="Number of Employees"
              variant="outlined"
              type="number"
              onChange={(e) =>
                GetData('NumberOfEmployees', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
