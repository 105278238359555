import React from 'react';
import { Collapse, theme } from 'antd';
import '../../index.css';

const getItems = (panelStyle) => [
  {
    key: '1',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>💹</h1>
        <h3 style={{ fontSize: '21px' }}>
          Optimizing Your Operations and Driving Growth
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "At Mining Roots, we prioritize sound data and informed decision-making in today's competitive business landscape. Our Industrial Analysis services empower your business with comprehensive insights and actionable recommendations for optimizing operations and driving sustainable growth."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '2',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🔍</h1>
        <h3 style={{ fontSize: '21px' }}>
          Comprehensive Data Collection and Assessment
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Our Industrial Analysis starts with systematic data collection using advanced techniques and tools, ensuring a complete and accurate understanding of your industry and operations.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '3',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🌱</h1>
        <h3 style={{ fontSize: '21px' }}>
          Identifying Opportunities for Growth
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Mining Roots excels in identifying growth opportunities within your industry. We analyze market trends, customer behaviors, and industry dynamics to uncover unexplored avenues for expansion, ensuring you stay ahead of the competition.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '4',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>📊</h1>
        <h3 style={{ fontSize: '21px' }}>Market Analysis</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'In the dynamic business landscape, data-driven decisions are crucial for success. Our R&D experts offer comprehensive market analysis to ground your strategies in solid insights. We help you identify trends, assess competition, and make informed choices to seize opportunities and mitigate risks.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '5',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🏁</h1>
        <h3 style={{ fontSize: '21px' }}>Competitive Analysis</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Understanding your competition is crucial for staying competitive. Our analysis provides insights into the strengths and weaknesses of your rivals. By benchmarking against industry leaders, we identify areas for gaining a strategic advantage.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '6',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>⚙️</h1>
        <h3 style={{ fontSize: '21px' }}>Operational Efficiency Enhancement</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'We collaborate to pinpoint inefficiencies in your operations, offering actionable recommendations for streamlining processes, optimizing the supply chain, and improving resource allocation. Our goal is to enhance operational efficiency, reduce costs, and improve productivity.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '7',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🚨</h1>
        <h3 style={{ fontSize: '21px' }}>
          Risk Mitigation and Contingency Planning
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Business success involves both growth and risk mitigation. Our Industrial Analysis includes a thorough risk assessment, helping you identify potential threats and develop contingency plans to safeguard your business from unforeseen challenges.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '8',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🤝</h1>
        <h3 style={{ fontSize: '21px' }}>Data-Driven Decision Making</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'In the fast-paced business world, informed decisions are essential. Mining Roots ensures our Industrial Analysis provides the data and insights you need to make confident, data-driven choices that align with your business objectives.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '9',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>💼</h1>
        <h3 style={{ fontSize: '21px' }}>A Collaborative Approach</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'At Mining Roots, we value collaboration. Working closely with your team, we ensure our analysis aligns with your business goals and strategies. Beyond recommendations, we empower your team to implement changes effectively.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '10',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>💪</h1>
        <h3 style={{ fontSize: '21px' }}>Empowering Your Business</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "Mining Roots' Industrial Analysis services empower your business for dynamic success. Beyond insights, we provide a roadmap for sustainable growth, operational efficiency, and long-term success."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
];
const SideCards = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  return (
    <div className="Collapse">
      <Collapse
        accordion
        size="large"
        bordered={false}
        defaultActiveKey={['1']}
        style={{
          background: token.colorBgContainer,
          zIndex: 5,
        }}
        items={getItems(panelStyle)}
      />
    </div>
  );
};
export default SideCards;
