import React from 'react';
import { Collapse, theme } from 'antd';
import '../../index.css';

const getItems = (panelStyle) => [
  {
    key: '1',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🚀</h1>
        <h3 style={{ fontSize: '21px' }}>
          Transforming Ideas into Viable Realities
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'At Mining Roots, we understand that innovation often starts with creativity. Our Concept Building & Evaluation services are designed to turn creative sparks into well-structured, & viable concepts. Whether in scientific or non-technological domains, we take pride in transforming concepts into efficient realities, driving innovation forward. We offer the guidance and expertise you need.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '2',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>💡</h1>
        <h3 style={{ fontSize: '21px' }}>Creative Ideation</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Concept building begins with creative ideation. Our expert team collaborates with you to brainstorm, refine, and expand initial ideas. We foster an environment that encourages innovation and out-of-the-box thinking, ensuring no idea is left unexplored.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '3',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>✨</h1>
        <h3 style={{ fontSize: '21px' }}>Design that Inspires</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "Our design team goes beyond aesthetics; we're dedicated to creating impactful designs. Whether it's product design, UI/UX, architecture, or any creative project, we ensure designs meet functional requirements and resonate with your target audience."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '4',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🤔</h1>
        <h3 style={{ fontSize: '21px' }}>Feasibility Assessment</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Not every innovative idea is immediately feasible. We conduct thorough feasibility assessments, evaluating technical, financial, and operational aspects to help you understand potential hurdles and opportunities associated with your concepts.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '5',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>📈</h1>
        <h3 style={{ fontSize: '21px' }}>Market Viability Analysis</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Concepts must meet market demands to succeed. Our market viability analysis explores customer needs, market trends, and competitive landscapes to help you gauge whether your concepts have the potential to meet real-world market demands.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '6',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🚨</h1>
        <h3 style={{ fontSize: '21px' }}>
          Risk Mitigation and Contingency Planning
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'In concept development, risk mitigation is crucial. We identify and assess potential risks associated with your concepts, developing contingency plans to help you navigate challenges effectively and make informed decisions.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '7',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🤖</h1>
        <h3 style={{ fontSize: '21px' }}>Prototyping and Testing</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "Prototyping and testing are essential in concept development. We rapidly create prototypes to bring your ideas to life, allowing you to visualize and refine concepts. This iterative process identifies strengths and weaknesses early, saving time and resources. Whether it's a physical product or a digital application, our process ensures a clear path to innovation."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '8',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🤝</h1>
        <h3 style={{ fontSize: '21px' }}>Interdisciplinary Expertise</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Our interdisciplinary team handles concepts spanning scientific, tech, and non-tech domains. Whether exploring cutting-edge technologies, social innovations, or creative arts, our experts are well-equipped to guide you through the development process.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '9',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🌱</h1>
        <h3 style={{ fontSize: '21px' }}>
          Sustainability and Eco-Friendly Designs
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "In today's world, sustainability is crucial. Our commitment to innovation includes creating eco-friendly designs that reduce environmental impact. We strive to find sustainable materials and processes to align your business with responsible practices."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '10',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🎨</h1>
        <h3 style={{ fontSize: '21px' }}>Efficiency Meets Aesthetics</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Design should not compromise between beauty and function. At Mining Roots, we harmonize both aspects to deliver efficient and visually appealing solutions. Our designs optimize processes, reduce operational costs, and enhance productivity while maintaining an artistic touch.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '11',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🛠️</h1>
        <h3 style={{ fontSize: '21px' }}>Customized Approach</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Every concept is unique, and our approach is tailored to your specific needs. Understanding the distinct challenges and requirements of different domains, we work closely with you to provide a customized roadmap for concept development.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '12',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>👤</h1>
        <h3 style={{ fontSize: '21px' }}>User-Centric Approach</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'We believe end-users are the ultimate judges of good design. Our philosophy is rooted in a user-centric approach, aiming to create visually appealing products and experiences that are easy to use, enhancing user satisfaction and driving adoption.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '13',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🌌</h1>
        <h3 style={{ fontSize: '21px' }}>Turning Visions into Realities</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Mining Roots is your creative partner, not just a design agency. We help unlock your creative potential and bring your ideas to life, guiding you through the journey from concept to execution. Our team works closely with you to ensure your ideas are translated into practical and actionable plans, handling the technical aspects so you can focus on your vision.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '14',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>📏</h1>
        <h3 style={{ fontSize: '21px' }}>Measuring Impact</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Innovation is an ongoing process, not a one-time event. We use key performance indicators and data-driven analytics to measure the impact of our design solutions. This ensures continuous improvement and refinement, keeping our designs relevant and efficient.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
];
const SideCards = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  return (
    <div className="Collapse">
      <Collapse
        accordion
        size="large"
        bordered={false}
        defaultActiveKey={['1']}
        style={{
          background: token.colorBgContainer,
          zIndex: 5,
        }}
        items={getItems(panelStyle)}
      />
    </div>
  );
};
export default SideCards;
