import React from 'react';
import { Tabs } from 'antd';
import ForStartups from './ForStartups';
import '../index.css';
import ForBigFirm from './ForBigFirm';
import ForFamilyBusiness from './ForFamilyBusiness';
import ForStudent from './ForStudent';
import ForColUni from './ForColUni';
import ForSME from './ForSME';
import ForSolo from './ForSolo';
import ForSide from './ForSide';
import { useSearchParams } from 'react-router-dom';

const WhoAreYou = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('id');
  let tabNumber = '1';
  switch (id) {
    case 'startup':
      tabNumber = '1';
      break;
    case 'small-medium-company':
      tabNumber = '2';
      break;
    case 'big-firm':
      tabNumber = '3';
      break;
    case 'family-business':
      tabNumber = '4';
      break;
    case 'student':
      tabNumber = '5';
      break;
    case 'college-and-university':
      tabNumber = '6';
      break;
    case 'solo-founder':
      tabNumber = '7';
      break;
    case 'side-hustler':
      tabNumber = '8';
      break;
    default:
      tabNumber = '1';
      break;
  }

  const items = [
    {
      key: '1',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'startup' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Startups
        </h4>
      ),
      children: <ForStartups />,
    },
    {
      key: '2',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(
              `?${new URLSearchParams({ id: 'small-medium-company' })}`
            )
          }
          style={{ fontSize: '20px' }}
        >
          SMEs
        </h4>
      ),
      children: <ForSME />,
    },
    {
      key: '3',
      label: (
        <h4
          id="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'big-firm' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Big Firm
        </h4>
      ),
      children: <ForBigFirm />,
    },
    {
      key: '4',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(
              `?${new URLSearchParams({ id: 'family-business' })}`
            )
          }
          style={{ fontSize: '20px' }}
        >
          Family Business
        </h4>
      ),
      children: <ForFamilyBusiness />,
    },
    {
      key: '5',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'student' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Student
        </h4>
      ),
      children: <ForStudent />,
    },
    {
      key: '6',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(
              `?${new URLSearchParams({ id: 'college-and-university' })}`
            )
          }
          style={{ fontSize: '20px' }}
        >
          College & University
        </h4>
      ),
      children: <ForColUni />,
    },
    {
      key: '7',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'solo-founder' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Solo Founder
        </h4>
      ),
      children: <ForSolo />,
    },
    {
      key: '8',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'side-hustler' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Side Hustler
        </h4>
      ),
      children: <ForSide />,
    },
  ];

  return (
    <div className="split-container">
      <div className="only-section">
        <Tabs
          centered
          className="desktop"
          defaultActiveKey={tabNumber}
          tabPosition="top"
          items={items}
        />
        <Tabs
          className="tablet"
          defaultActiveKey={tabNumber}
          tabPosition="top"
          items={items}
        />
        <Tabs
          className="mobile"
          defaultActiveKey={tabNumber}
          tabPosition="top"
          style={{
            height: '100%',
            position: 'sticky',
          }}
          items={items}
        />
      </div>
    </div>
  );
};
export default WhoAreYou;
