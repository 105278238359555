import React, { useState, Suspense } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Card } from 'antd';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Carousel } from 'antd';
import image1 from '../../images/Industrial Analysis Unearthing Hidden Potential in Your Industry.webp';
import image2 from '../../images/Benefits of choosing Mining Roots for Industrial Analysis.webp';
import '../../index.css';
import ValuesDrawer from './ValuesDrawer';
import MobileSideCard from './MobileSideCard';
import Benefits from './Benefits';

export default function Data() {
  return (
    <div>
      <div style={{ paddingBottom: '1rem' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartOne />
        </Suspense>
      </div>
      <div
        className="mobile"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div
        className="tablet"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <PartTwo />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartThree />
        </Suspense>
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartFour />
        </Suspense>
      </div>
    </div>
  );
}

function PartOne() {
  localStorage.setItem('topImage', image1);
  const [topImage, setTopImage] = useState(
    localStorage.getItem('topImage') || image1
  );
  console.log(setTopImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <div style={{ textAlign: 'center' }}>
            <LazyLoadImage
              src={topImage}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={8}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{ paddingBottom: '20px', fontSize: '35px' }}
            >
              Unearthing{' '}
              <span style={{ color: '#BC13FE' }}>Hidden Potential</span> in Your{' '}
              <span style={{ color: '#42A047' }}>Industry</span>
            </h1>
            <h3
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, we understand that the key to optimizing your
              business lies in a deep understanding of your industry. Our team
              of experienced analysts utilizes sophisticated tools and
              methodologies to provide you with comprehensive industrial
              analysis, uncovering hidden potential and driving informed
              decision-making.
            </h3>
            <h3
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, we understand that the key to optimizing your
              business lies in a deep understanding of your industry. Our team
              of experienced analysts utilizes sophisticated tools and
              methodologies to provide you with comprehensive industrial
              analysis, uncovering hidden potential and driving informed
              decision-making.
            </h3>
          </div>
        </Col>
      </Row>
      <h3
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
        }}
      >
        At Mining Roots, we understand that the key to optimizing your business
        lies in a deep understanding of your industry. Our team of experienced
        analysts utilizes sophisticated tools and methodologies to provide you
        with comprehensive industrial analysis, uncovering hidden potential and
        driving informed decision-making.
      </h3>
    </div>
  );
}

function PartTwo() {
  return (
    <div>
      <h2
        style={{
          paddingBottom: '2rem',
          fontSize: '27px',
          fontWeight: '500',
          color: '#0087FF',
        }}
      >
        <span style={{ fontWeight: '500', fontSize: '27px' }}>
          Industrial Analysis
        </span>{' '}
        Offerings
      </h2>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>📈</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Market Landscape Assessment
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Data Collection and Assessment</h4>
            </li>
            <li className="list">
              <h4>Industry Size and Growth Analysis</h4>
            </li>
            <li className="list">
              <h4>Competitive Landscape Analysis</h4>
            </li>
            <li className="list">
              <h4>Market Segmentation and Analysis</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🖐️</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Porter's Five Forces Analysis
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Bargaining Power of Buyers and Suppliers</h4>
            </li>
            <li className="list">
              <h4>Competitive Rivalry</h4>
            </li>
            <li className="list">
              <h4>Threat of New Entrants</h4>
            </li>
            <li className="list">
              <h4>Threat of Substitutes</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>✅</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                SWOT Analysis
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Strengths Analysis</h4>
            </li>
            <li className="list">
              <h4>Weaknesses Analysis</h4>
            </li>
            <li className="list">
              <h4>Opportunities Analysis</h4>
            </li>
            <li className="list">
              <h4>Threats Analysis</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>💵</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Financial Analysis
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Financial Benchmarking</h4>
            </li>
            <li className="list">
              <h4>Cost Analysis</h4>
            </li>
            <li className="list">
              <h4>Profitability Analysis</h4>
            </li>
            <li className="list">
              <h4>Risk Mitigation and Contingency</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>📈</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Trend Analysis
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Emerging Technology Analysis</h4>
            </li>
            <li className="list">
              <h4>Consumer Behavior Analysis</h4>
            </li>
            <li className="list">
              <h4>Social and Economic Trend Analysis</h4>
            </li>
            <li className="list">
              <h4>Operational Efficiency Enhancement</h4>
            </li>
            <li className="list">
              <h4>Data-Driven Decision Making</h4>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
}

function PartThree() {
  localStorage.setItem('bottomImage', image2);
  const [bottomImage, setBottomImage] = useState(
    localStorage.getItem('bottomImage') || image2
  );
  console.log(setBottomImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <LazyLoadImage
            src={bottomImage}
            alt=""
            style={{ width: '100%', height: '100%', paddingBottom: '1rem' }}
          />
        </Col>
        <Col sm={8}>
          <h2 style={{ fontSize: '27px', color: '#0087FF' }}>
            Benefits of Choosing Us
          </h2>
          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            {/* <div style={{ height: '100%' }}> */}
            <Benefits />
            {/* </div> */}
            <ValuesDrawer />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function PartFour() {
  return (
    <div style={{ paddingTop: '2rem' }}>
      <h4 style={{ textAlign: 'center', fontSize: '20px' }}>
        These offerings empower clients to optimize their operations, identify
        new opportunities for growth, make informed decisions, and manage risks
        effectively. The goal is to ensure that clients have the tools and
        insights needed to stay competitive and succeed in their respective
        industries.
      </h4>
    </div>
  );
}
