import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { CompanyName } from './Common';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function Startup() {
  const devStages = [
    {
      value: 'Idea Stage',
      label: 'Idea Stage',
    },
    {
      value: 'Prototype Stage',
      label: 'Prototype Stage',
    },
    {
      value: 'MVP Stage',
      label: 'MVP Stage',
    },
    {
      value: 'Early Revenue Stage',
      label: 'Early Revenue Stage',
    },
    {
      value: 'Growth Stage',
      label: 'Growth Stage',
    },
    {
      value: 'Established Stage',
      label: 'Established Stage',
    },
    {
      value: 'Scaling Stage',
      label: 'Scaling Stage',
    },
  ];

  const fundingStatus = [
    {
      value: 'Angel Investment',
      label: 'Angel Investment',
    },
    {
      value: 'Venture Debt',
      label: 'Venture Debt',
    },
    {
      value: 'Bootstrapped',
      label: 'Bootstrapped',
    },
    {
      value: 'Pre-Seed',
      label: 'Pre-Seed',
    },
    {
      value: 'Seed Funding',
      label: 'Seed Funding',
    },
    {
      value: 'Series A',
      label: 'Series A',
    },
    {
      value: 'Series B',
      label: 'Series B',
    },
    {
      value: 'Series C',
      label: 'Series C',
    },
    {
      value: 'Later Stage',
      label: 'Later Stage',
    },
    {
      value: 'Acquired',
      label: 'Acquired',
    },
    {
      value: 'Not Applicable',
      label: 'Not Applicable',
    },
  ];

  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <CompanyName />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <StageOfDevelopment options={devStages} />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <FundingStatus options={fundingStatus} />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <TargetMarket />
      </div>
    </div>
  );
}

const StageOfDevelopment = ({ options }) => {
  const outerTheme = useTheme();
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="development-stage"
            select
            label="Stage of Development"
            variant="outlined"
            onChange={(e) => GetData('DevelopmentStage', e.target.value, false)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </div>
    </Box>
  );
};

const FundingStatus = ({ options }) => {
  const outerTheme = useTheme();
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="funding-status"
            select
            label="Current funding status"
            variant="outlined"
            onChange={(e) => GetData('FundingStatus', e.target.value, false)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </div>
    </Box>
  );
};

const TargetMarket = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="target-market"
              label="Target Market"
              variant="outlined"
              onChange={(e) => GetData('TargetMarket', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
