import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export const industryOptions = [
  {
    value: 'Agriculture',
    label: 'Agriculture',
  },
  {
    value: 'Arts, Entertainment and Recreation',
    label: 'Arts, Entertainment and Recreation',
  },
  {
    value: 'Construction',
    label: 'Construction',
  },
  {
    value: 'Educational Services',
    label: 'Educational Services',
  },
  {
    value: 'Finance and Insurance',
    label: 'Finance and Insurance',
  },
  { value: 'Fishing and Hunting', label: 'Fishing and Hunting' },
  { value: 'Forestry', label: 'Forestry' },
  {
    value: 'Health Care and Social Assistance',
    label: 'Health Care and Social Assistance',
  },
  {
    value: 'Hospitality and Food Services',
    label: 'Hospitality and Food Services',
  },
  { value: 'Information', label: 'Information' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Mining', label: 'Mining' },
  { value: 'Oil and Gas Extraction', label: 'Oil and Gas Extraction' },
  { value: 'Public Administration', label: 'Public Administration' },
  {
    value: 'Real Estate and Rental and Leasing',
    label: 'Real Estate and Rental and Leasing',
  },
  { value: 'Retail Trade', label: 'Retail Trade' },
  {
    value: 'Scientific  and Technical Services',
    label: 'Scientific  and Technical Services',
  },
  { value: 'Technology', label: 'Technology' },
  { value: 'Transportation', label: 'Transportation' },
  { value: 'Warehousing', label: 'Warehousing' },
  { value: 'Wholesale Trade', label: 'Wholesale Trade' },
  { value: 'Other', label: 'Other' },
];

export const CompanyName = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="company-name"
              label="Company Name"
              variant="outlined"
              onChange={(e) => GetData('CompanyName', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

export const Industry = ({ options, onSelect }) => {
  const outerTheme = useTheme();
  function select(name, e, f) {
    f(e);
    GetData(name, e, false);
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="industry"
            select
            label="Industry"
            variant="outlined"
            onChange={(e) => select('Industry', e.target.value, onSelect)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </div>
    </Box>
  );
};

export const OtherIndustry = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="other-industry"
              label="Other Industry"
              variant="outlined"
              onChange={(e) => GetData('Industry', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

export const Department = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="department"
              label="Department"
              variant="outlined"
              onChange={(e) => GetData('Department', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

export const Institute = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="institute"
              label="Institute"
              variant="outlined"
              onChange={(e) => GetData('Institute', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
