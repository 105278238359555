import React from 'react';
import SideCards from './SideCards';
import Data from './Data';
import { ContactCard } from '../../ContactDrawer';
import '../../index.css';

export default function ResearchAndDev() {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('id');
  console.log(id);
  if (id === 'rd-unique-values') {
  }
  return (
    <div className="split-container">
      <div className="left-section">
        <Data />
        <ContactCard />
      </div>
      <div className="right-section">
        <div>
          <SideCards />
        </div>
      </div>
    </div>
  );
}
