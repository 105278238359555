import { ContactDrawerButton } from './ContactDrawer';
import Logo from './images/MR3.png';
import './index.css';
import React from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
} from 'mdb-react-ui-kit';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Drawer } from 'antd';
import Sitemap from './Sitemap';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function App() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MDBNavbar expand="md" light bgColor="light" sticky style={{ zIndex: 1 }}>
        <MDBContainer fluid>
          <MDBNavbarBrand style={{ marginRight: '0' }}>
            <Button
              onClick={showDrawer}
              style={{ width: '22px', height: '22px' }}
            >
              <MenuIcon style={{ width: '22px', height: '22px' }} />
            </Button>
          </MDBNavbarBrand>
          {/* </div> */}
          <MDBNavbarBrand href="/home">
            <img
              src={Logo}
              alt="Mining Roots Logo"
              style={{ width: '40px', height: '40px' }}
            />
          </MDBNavbarBrand>
          <MDBNavbarBrand
            href="/home"
            style={{ fontWeight: '500', fontSize: '23px' }}
          >
            Mining Roots®
          </MDBNavbarBrand>
          {/* <div className="desktop"> */}
          <MDBNavbarNav className="mr-auto mb-lg-0">
            <MDBNavbarItem>
              <MDBNavbarLink
                href="/home"
                style={{
                  fontSize: '20px',
                  paddingRight: '0.5rem',
                  paddingLeft: '0.5rem',
                  color: 'darkgreen',
                }}
              >
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                href="/career"
                style={{
                  fontSize: '20px',
                  paddingRight: '0.5rem',
                  paddingLeft: '0.5rem',
                  color: 'darkgreen',
                }}
              >
                Career
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                href="https://mining-roots.notion.site/Portfolio-Mining-Roots-509e0efcea954cbdaafae396019df6a1?pvs=4"
                target="_blank"
                style={{
                  fontSize: '20px',
                  paddingRight: '0.5rem',
                  paddingLeft: '0.5rem',
                  color: 'darkgreen',
                }}
              >
                Portfolio
              </MDBNavbarLink>
            </MDBNavbarItem>
            {/* <MDBNavbarItem>
              <MDBNavbarLink
                href="https://www.linkedin.com/company/mining-roots/"
                target="_blank"
                style={{
                  fontSize: '20px',
                  paddingRight: '0.5rem',
                  paddingLeft: '0.5rem',
                  color: 'black',
                  paddingTop: '0.3rem',
                }}
              >
                <LinkedInIcon fontSize="medium" />
              </MDBNavbarLink>
            </MDBNavbarItem> */}
          </MDBNavbarNav>
          <MDBNavbarLink
            href="https://www.linkedin.com/company/mining-roots/"
            target="_blank"
            style={{
              fontSize: '20px',
              paddingRight: '0.8rem',
              paddingLeft: '0.5rem',
              color: 'black',
            }}
          >
            <LinkedInIcon fontSize="medium" />
          </MDBNavbarLink>
          <ContactDrawerButton />
        </MDBContainer>
      </MDBNavbar>
      <Drawer
        id="sitemap"
        title="Sitemap"
        placement="left"
        onClose={onClose}
        open={open}
        size="medium"
      >
        <Sitemap />
      </Drawer>
    </>
  );
}
