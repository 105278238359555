import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function SideHustler() {
  const [selectedOption, setSelectedOption] = useState('');
  const stageOptions = [
    {
      value: 'Conceptualization',
      label: 'Conceptualization',
    },
    {
      value: 'Planning',
      label: 'Planning',
    },
    {
      value: 'Execution',
      label: 'Execution',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Monetization',
      label: 'Monetization',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];
  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ProjectIdea />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <CurrentStage options={stageOptions} onSelect={setSelectedOption} />
        {selectedOption === 'Other' && <OtherStage />}
      </div>
    </div>
  );
}

const ProjectIdea = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="project-idea"
              label="Project Idea"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) => GetData('ProjectIdea', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const CurrentStage = ({ options, onSelect }) => {
  const outerTheme = useTheme();
  function select(name, e, f) {
    f(e);
    GetData(name, e, false);
  }
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="current-stage"
            select
            label="Current Stage"
            variant="outlined"
            onChange={(e) => select('CurrentStage', e.target.value, onSelect)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </Box>
    </div>
  );
};

const OtherStage = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="other-stage"
              label="Other Stage"
              variant="outlined"
              onChange={(e) => GetData('CurrentStage', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
