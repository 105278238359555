import React from 'react';
import { Collapse, theme } from 'antd';
import '../../index.css';

const getItems = (panelStyle) => [
  {
    key: '1',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🚀</h1>
        <h3 style={{ fontSize: '21px' }}>
          Innovative Solutions That Drive Progress
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'At Mining Roots, our Research & Development services are the cornerstone of our commitment to enhancing efficiency and fostering technological advancements across industries.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '2',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🔬</h1>
        <h3 style={{ fontSize: '21px' }}>Scientific Excellence</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "Our team of  experts leads in scientific breakthroughs, pushing knowledge boundaries to solve complex challenges. Whether it's fundamental research, applied sciences, or technology development, we have the expertise to meet your needs."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '3',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🌐</h1>
        <h3 style={{ fontSize: '21px' }}>Technological Advancements</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'In a fast-paced world, staying competitive means embracing technology. Our R&D team, versed in the latest trends, identifies opportunities for advancement in your industry. From software solutions to hardware optimization, we provide services to keep your business ahead.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '4',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🔄</h1>
        <h3 style={{ fontSize: '21px' }}>Innovation Integration</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Innovation goes beyond labs. We integrate creative ideas seamlessly into your processes, ensuring practical implementation for real-world solutions that drive your business forward.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '5',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🛠️</h1>
        <h3 style={{ fontSize: '21px' }}>Customized Solutions</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "We recognize the unique challenges and goals of each industry and business. Our customized R&D solutions are tailored to your specific needs, whether it's product development or process optimization. We're here to collaborate with you to achieve your objectives."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '6',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🌟</h1>
        <h3 style={{ fontSize: '21px' }}>Empowering Your Success</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "Mining Roots' R&D services go beyond idea generation; they turn ideas into tangible results. We diligently empower your success with the knowledge, innovation, and strategies you need to thrive in today's competitive world."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
];
const SideCards = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  return (
    <div className="Collapse">
      <Collapse
        accordion
        size="large"
        bordered={false}
        defaultActiveKey={['1']}
        style={{
          background: token.colorBgContainer,
          zIndex: 5,
        }}
        items={getItems(panelStyle)}
      />
    </div>
  );
};
export default SideCards;
