// import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/css';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import '../../index.css';

// // import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../../index.css';

// import required modules

export default function App() {
  return (
    <div style={{ height: '20vh', paddingLeft: '10px', paddingRight: '10px' }}>
      <Swiper
        // direction={'vertical'}
        centeredSlides={true}
        style={{
          '--swiper-navigation-color': '#000',
          '--swiper-pagination-color': '#000',
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          type: 'progressbar',
        }}
        loop={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Maximize your return on investment in technology.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Increase efficiency, productivity, and competitiveness.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Enhance security and data protection.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Reduce costs and optimize resource allocation.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Improve decision-making through data-driven insights.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Gain a competitive advantage through early adoption of
                      emerging technologies.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Stay ahead of the curve in a rapidly changing
                      technological landscape.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
