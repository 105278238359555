import * as React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
// import NavigationBar from './NavigationBar';
import './index.css';
// import { BootCard } from './BootCard';
import HomeLayout from './HomeLayout';
import HomeLayoutMobile from './HomeLayoutMobile';

export default function Home() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="center" style={{ background: '#FAFAFA' }}>
        <div className="home">
          <div className="desktop">
            <HomeLayout />
          </div>
          <div className="tablet">
            <HomeLayoutMobile />
          </div>
          <div className="mobile">
            <HomeLayoutMobile />
          </div>
        </div>
      </div>
    </StyledEngineProvider>
  );
}
