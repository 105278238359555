import React, { useState, Suspense } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Card } from 'antd';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Carousel } from 'antd';
import image1 from '../../images/Technological Consultancy Navigating the Evolving Landscape of Technology.webp';
import image2 from '../../images/Benefits of Mining Roots_ Technological Consultancy.webp';
import '../../index.css';
import ValuesDrawer from './ValuesDrawer';
import MobileSideCard from './MobileSideCard';
import Benefits from './Benefits';

export default function Data() {
  return (
    <div>
      <div style={{ paddingBottom: '1rem' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartOne />
        </Suspense>
      </div>
      <div
        className="mobile"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div
        className="tablet"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <PartTwo />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartThree />
        </Suspense>
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartFour />
        </Suspense>
      </div>
    </div>
  );
}

function PartOne() {
  localStorage.setItem('topImage', image1);
  const [topImage, setTopImage] = useState(
    localStorage.getItem('topImage') || image1
  );
  console.log(setTopImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <div style={{ textAlign: 'center' }}>
            <LazyLoadImage
              src={topImage}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={8}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{ paddingBottom: '20px', fontSize: '35px' }}
            >
              Navigating the{' '}
              <span style={{ color: '#BC13FE' }}>Evolving Landscape</span> of
              Your <span style={{ color: '#42A047' }}>Technology</span>
            </h1>
            <h3
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              In today's rapidly evolving technological landscape, staying ahead
              of the curve is critical for success. At Mining Roots, our team of
              experienced technological consultants provides comprehensive
              guidance and support, empowering you to leverage the latest
              advancements and optimize your operations.
            </h3>
            <h3
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              In today's rapidly evolving technological landscape, staying ahead
              of the curve is critical for success. At Mining Roots, our team of
              experienced technological consultants provides comprehensive
              guidance and support, empowering you to leverage the latest
              advancements and optimize your operations.
            </h3>
          </div>
        </Col>
      </Row>
      <h3
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
        }}
      >
        In today's rapidly evolving technological landscape, staying ahead of
        the curve is critical for success. At Mining Roots, our team of
        experienced technological consultants provides comprehensive guidance
        and support, empowering you to leverage the latest advancements and
        optimize your operations.
      </h3>
    </div>
  );
}

function PartTwo() {
  return (
    <div>
      <h2
        style={{
          paddingBottom: '2rem',
          fontSize: '27px',
          color: '#0087FF',
        }}
      >
        <span style={{ fontWeight: '500', fontSize: '27px' }}>
          Technological Consultancy
        </span>{' '}
        Offerings
      </h2>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🔍</h1>
              <h3
                style={{
                  fontSize: '25px',
                  color: '#0087ff',
                  textWrap: 'balance',
                }}
              >
                Technology Needs Assessment
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Comprehensive Technology Infrastructure Audit</h4>
            </li>
            <li className="list">
              <h4>Identification of Specific Needs and Challenges</h4>
            </li>
            <li className="list">
              <h4>Development of a Technology Roadmap</h4>
            </li>
            <li className="list">
              <h4>Innovation and Research Collaboration</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🛠️</h1>
              <h3
                style={{
                  fontSize: '25px',
                  color: '#0087ff',
                  textWrap: 'balance',
                }}
              >
                Technology Selection & Implementation
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Evaluation and Selection of Optimal Technologies</h4>
            </li>
            <li className="list">
              <h4>Data Migration and Conversion Support</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🔄</h1>
              <h3
                style={{
                  fontSize: '25px',
                  color: '#0087ff',
                  textWrap: 'balance',
                }}
              >
                Technology Optimization & Monitoring
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Efficiency Optimization</h4>
            </li>
            <li className="list">
              <h4>Performance Optimization</h4>
            </li>
            <li className="list">
              <h4>Security and Risk Management</h4>
            </li>
            <li className="list">
              <h4>Data Analytics and Reporting</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🚀</h1>
              <h3
                style={{
                  fontSize: '25px',
                  color: '#0087ff',
                  textWrap: 'balance',
                }}
              >
                Emerging Technology Analysis & Adoption
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Staying Ahead with Emerging Technology Trends</h4>
            </li>
            <li className="list">
              <h4>Future Technology Adoption Plan</h4>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
}

function PartThree() {
  localStorage.setItem('bottomImage', image2);
  const [bottomImage, setBottomImage] = useState(
    localStorage.getItem('bottomImage') || image2
  );
  console.log(setBottomImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <LazyLoadImage
            src={bottomImage}
            alt=""
            style={{ width: '100%', height: '100%', paddingBottom: '1rem' }}
          />
        </Col>
        <Col sm={8}>
          <h2 style={{ fontSize: '27px', color: '#0087FF' }}>
            Benefits of Choosing Us
          </h2>
          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Benefits />
            <ValuesDrawer />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function PartFour() {
  return (
    <div style={{ paddingTop: '2rem' }}>
      <h4 style={{ textAlign: 'center', fontSize: '20px' }}>
        These offerings empower clients to harness the power of technology, make
        data-driven decisions, enhance operational efficiency, and maintain a
        competitive edge in a rapidly evolving technological landscape. The aim
        is to provide the guidance and expertise needed to navigate the
        complexities of technology and leverage innovation for business success.
      </h4>
    </div>
  );
}
