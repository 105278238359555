import React from 'react';
import { Collapse, theme } from 'antd';
import '../../index.css';

const getItems = (panelStyle) => [
  {
    key: '1',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>💻</h1>
        <h3 style={{ fontSize: '21px' }}>
          Unlocking the Power of Technology for Your Success
        </h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "In a rapidly evolving technological landscape, staying ahead is a continuous challenge. Mining Roots provides cutting-edge Technological Consultancy services to help you harness technology's full potential, ensuring your business remains competitive, secure, and advanced."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '2',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🗺️</h1>
        <h3 style={{ fontSize: '21px' }}>Strategic Technology Roadmaps</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Our Technological Consultancy services start with strategic technology roadmaps. Collaborating closely with your team, we understand your business goals, challenges, and industry dynamics. We then create a tailored technology roadmap aligned with your objectives, optimizing your technology investments for success.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '3',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🔄</h1>
        <h3 style={{ fontSize: '21px' }}>Technology Integration</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Our experts excel in the latest technological trends, including cloud solutions, IoT, AI, and emerging technologies. We guide you through seamless technology integration, ensuring your technology stack aligns with your business strategies.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '4',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🔐</h1>
        <h3 style={{ fontSize: '21px' }}>Cybersecurity and Data Protection</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'In a digital world, safeguarding your data and operations is crucial. Our Technological Consultancy includes cybersecurity and data protection assessments. We identify vulnerabilities, develop security strategies, and recommend best practices to keep your business secure against evolving threats.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '5',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>⚙️</h1>
        <h3 style={{ fontSize: '21px' }}>Efficiency Optimization</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Efficiency is the key to profitability. We analyze your technology infrastructure and operational processes to identify areas for streamlining operations, reducing costs, and enhancing productivity. Our tailored recommendations aim to maximize efficiency within your organization.'
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '6',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>📊</h1>
        <h3 style={{ fontSize: '21px' }}>Measurable Results</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          'Focused on delivering tangible results, our Technological Consultancy services are results-oriented, employing key performance indicators and metrics to track the impact of our recommendations. '
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
  {
    key: '7',
    label: (
      <div>
        <h1 style={{ fontSize: '30px' }}>🤝</h1>
        <h3 style={{ fontSize: '21px' }}>Your Trusted Technology Partner</h3>
      </div>
    ),
    children: (
      <h4 style={{ textAlign: 'justify', fontSize: '18px' }}>
        {
          "Mining Roots is not just a consultancy firm; we're your trusted technology partner. Here to help you navigate the complex world of technology, ensuring your business remains competitive and leveraging innovation for your success."
        }
      </h4>
    ),
    style: panelStyle,
    showArrow: false,
  },
];
const SideCards = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  return (
    <div className="Collapse">
      <Collapse
        accordion
        size="large"
        bordered={false}
        defaultActiveKey={['1']}
        style={{
          background: token.colorBgContainer,
          zIndex: 5,
        }}
        items={getItems(panelStyle)}
      />
    </div>
  );
};
export default SideCards;
