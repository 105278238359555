import React from 'react';
import SideCards from './SideCards';
import Data from './Data';
import { ContactCard } from '../../ContactDrawer';
import '../../index.css';

export default function TechnoConsult() {
  return (
    <div className="split-container">
      <div className="left-section">
        <Data />
        <ContactCard />
        {/* <Footer /> */}
      </div>
      <div className="right-section">
        <div>
          <SideCards />
        </div>
      </div>
    </div>
  );
}
