import React, { useState, Suspense } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import './index.css';
import RD from './images/What We Do Research _ Development.webp';
import IA from './images/What We Do Industrial Analysis.webp';
import TC from './images/What We Do Technological Consultancy.webp';
import CB from './images/What We Do Concept Building _ Evaluation.webp';
import Contact from './images/contact us 1.webp';
import { Drawer } from 'antd';
import ContactUs from './contact/ContactUs';

export default function HomeLayoutMobile() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="tablet-home" style={{ background: '#FAFAFA' }}>
      <Suspense fallback={<div>Loading</div>}>
        <Card
          border="dark"
          style={{
            color: '#000000',
            marginTop: '2.5vh',
            marginBottom: '0',
            borderRadius: '2rem',
            boxShadow: '7px 7px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Card.Body>
            <Card.Title>
              <h1 style={{ fontSize: '3.5vh', color: '#0087ff' }}>
                Unlocking Efficiency & Innovation
              </h1>
            </Card.Title>
            <Card.Text>
              <p style={{ fontSize: '1.7vh', textAlign: 'justify' }}>
                Welcome to Mining Roots, your trusted partner for comprehensive
                scientific and technological solutions designed to optimize your
                operations and propel your business forward. We specialize in
                research, design, industrial analysis, concept building, and
                evaluations across various industries. As pioneers in our field,
                we provide innovative solutions to enhance efficiency, security,
                and technological advancements.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Suspense>
      <Link to={'/offerings/concept-building-and-evaluation'}>
        <Suspense fallback={<div>Loading</div>}>
          <Card
            border="dark"
            style={{
              cursor: 'pointer',
              color: '#000000',
              marginTop: '2.51vh',
              marginBottom: '0',
              borderRadius: '2rem',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Card.Img
              src={CB}
              alt="Card image"
              style={{ height: '20vh', borderRadius: '2rem' }}
            />
            <Card.ImgOverlay>
              <Card.Title>
                <h1 style={{ fontSize: '5vh' }}>🛠️</h1>
                <h1 style={{ fontSize: '3.5vh', marginBottom: '0' }}>
                  Concept
                </h1>
                <h1 style={{ fontSize: '3.5vh' }}>Building</h1>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
        </Suspense>
      </Link>
      <Link to={'/offerings/industry-analysis'}>
        <Suspense fallback={<div>Loading</div>}>
          <Card
            border="dark"
            style={{
              cursor: 'pointer',
              color: '#000000',
              marginTop: '2.51vh',
              marginBottom: '0',
              borderRadius: '2rem',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Card.Img
              src={IA}
              alt="Card image"
              style={{ height: '20vh', borderRadius: '2rem' }}
            />
            <Card.ImgOverlay>
              <Card.Title>
                <h1 style={{ fontSize: '5vh' }}>🏭</h1>
                <h1 style={{ fontSize: '3.5vh', marginBottom: '0' }}>
                  Industrial
                </h1>
                <h1 style={{ fontSize: '3.5vh' }}>Analysis</h1>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
        </Suspense>
      </Link>
      <Link to={'/offerings/research-and-development'}>
        <Suspense fallback={<div>Loading</div>}>
          <Card
            border="dark"
            style={{
              cursor: 'pointer',
              color: '#000000',
              marginTop: '2.51vh',
              marginBottom: '0',
              borderRadius: '2rem',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Card.Img
              src={RD}
              alt="Card image"
              style={{ height: '20vh', borderRadius: '2rem' }}
            />
            <Card.ImgOverlay>
              <Card.Title>
                <h1 style={{ fontSize: '5vh' }}>🚀</h1>
                <h1 style={{ fontSize: '3.5vh', marginBottom: '0' }}>
                  Research &
                </h1>
                <h1 style={{ fontSize: '3.5vh' }}>Development</h1>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
        </Suspense>
      </Link>
      <Link to={'/offerings/technology-consultancy'}>
        <Suspense fallback={<div>Loading</div>}>
          <Card
            border="dark"
            style={{
              cursor: 'pointer',
              color: '#000000',
              marginTop: '2.51vh',
              marginBottom: '0',
              borderRadius: '2rem',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Card.Img
              src={TC}
              alt="Card image"
              style={{ height: '20vh', borderRadius: '2rem' }}
            />
            <Card.ImgOverlay>
              <Card.Title>
                <h1 style={{ fontSize: '5vh' }}>💡</h1>
                <h1 style={{ fontSize: '3.5vh', marginBottom: '0' }}>
                  Technological
                </h1>
                <h1 style={{ fontSize: '3.5vh' }}>Consultancy</h1>
              </Card.Title>
              {/* <Card.Text>Stay ahead of the competition</Card.Text> */}
            </Card.ImgOverlay>
          </Card>
        </Suspense>
      </Link>
      <Link to={'/who-are-you'}>
        <Suspense fallback={<div>Loading</div>}>
          <Card
            border="dark"
            style={{
              cursor: 'pointer',
              color: '#000000',
              marginTop: '2.51vh',
              marginBottom: '0',
              // background: '#feb7ad',
              background: 'linear-gradient(to right, #feb7ad, #fecfc8)',
              borderRadius: '2rem',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Card.Body>
              <Card.Title>
                <h1 style={{ fontSize: '3.5vh', paddingTop: '1.5vh' }}>
                  Who Are You?
                </h1>
              </Card.Title>
            </Card.Body>
          </Card>
        </Suspense>
      </Link>
      <Suspense fallback={<div>Loading</div>}>
        <Card
          border="dark"
          style={{
            color: '#000000',
            marginTop: '2.51vh',
            marginBottom: '0',
            background: 'linear-gradient(to bottom, #cf9fff, #ddbbff)',
            borderRadius: '2rem',
            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Card.Body>
            <Card.Title>
              <h1 style={{ paddingBottom: '2vh', fontSize: '3.5vh' }}>
                Why Choose Us?
              </h1>
            </Card.Title>
            <Card.Text>
              <h4 style={{ fontSize: '2.5vh' }}>
                <span style={{ fontSize: '3.5vh' }}>✨</span> Innovation &
                Creativity
              </h4>
              <p style={{ fontSize: '1.7vh' }}>
                We craft groundbreaking solutions with diverse expertise
              </p>
              <h4 style={{ fontSize: '2.5vh' }}>
                <span style={{ fontSize: '3.5vh' }}>⏱️</span> Efficiency & Cost
                Reduction
              </h4>
              <p style={{ fontSize: '1.7vh' }}>
                Streamline processes and maximize your bottom line
              </p>
              <h4 style={{ fontSize: '2.5vh' }}>
                <span style={{ fontSize: '3.5vh' }}>🛡️</span> Security & Trust
              </h4>
              <p style={{ fontSize: '1.7vh' }}>
                Safeguard your data and operations with our secure solutions
              </p>
              <h4 style={{ fontSize: '2.5vh', textWrap: 'nowrap' }}>
                <span style={{ fontSize: '3.5vh' }}>🌐</span> Technological
                Advancements
              </h4>
              <p style={{ fontSize: '1.7vh' }}>
                Stay ahead of the curve with leading-edge technologies
              </p>
              <h4 style={{ fontSize: '2.5vh' }}>
                <span style={{ fontSize: '3.5vh' }}>🤝</span> Client-Centric
                Approach
              </h4>
              <p style={{ fontSize: '1.7vh' }}>
                We collaborate closely to achieve shared success
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Suspense>
      <Suspense fallback={<div>Loading</div>}>
        <Card
          onClick={showDrawer}
          border="dark"
          style={{
            cursor: 'pointer',
            color: '#4F4F4F',
            marginTop: '2.51vh',
            marginBottom: '0',
            borderRadius: '2rem',
            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Card.Img
            src={Contact}
            alt="Card image"
            style={{ height: '42.7vh', borderRadius: '2rem' }}
          />
          <Card.ImgOverlay>
            <Card.Title>
              <h1
                style={{
                  paddingBottom: '2vh',
                  fontSize: '3.5vh',
                  color: '#FFFFFF',
                }}
              >
                Contact Us
              </h1>
            </Card.Title>
            <Card.Text>
              <h4
                style={{
                  fontSize: '2.5vh',
                  paddingTop: '2vh',
                  color: '#FFFFFF',
                }}
              >
                Get a free consultation
              </h4>
              <h4
                style={{
                  fontSize: '2.5vh',
                  paddingTop: '3vh',
                  color: '#FFFFFF',
                }}
              >
                Based in Mumbai, India
              </h4>
              <h4
                style={{
                  fontSize: '2.5vh',
                  paddingTop: '1vh',
                  color: '#FFFFFF',
                }}
              >
                team@mining-roots.com
              </h4>
              <h4
                style={{
                  fontSize: '2.5vh',
                  paddingTop: '5vh',
                  color: '#FFFFFF',
                }}
              >
                <i>
                  <u>Join The Revolution</u>
                </i>
              </h4>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </Suspense>
      <Drawer
        title="Contact Us Form"
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
        id="contact"
      >
        <ContactUs />
      </Drawer>
    </div>
  );
}
