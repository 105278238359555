import React, { useState } from 'react';
import { Drawer } from 'antd';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../index.css';
import { Stack } from 'react-bootstrap';

export default function ValuesDrawer() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card
        border="dark"
        style={{
          width: '100%',
          cursor: 'pointer',
          borderRadius: '20px',
        }}
        onClick={showDrawer}
      >
        <Card.Body
          style={{
            backgroundColor: '#FAFAFA',
            borderRadius: '20px',
          }}
        >
          <Card.Title
            style={{
              marginBottom: '0px',
              textAlign: 'center',
              color: '#bc13fe',
            }}
          >
            {
              <h3 style={{ margin: '0', color: '#bc13fe', fontSize: '20px' }}>
                Learn More About Values...
              </h3>
            }
          </Card.Title>
        </Card.Body>
      </Card>
      <Drawer
        // id="values"
        title={
          <h2
            style={{
              fontSize: '20px',
              marginBottom: '0',
              color: 'black',
              textAlign: 'start',
            }}
          >
            Unique Value Propositions
          </h2>
        }
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
      >
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'end',
            paddingTop: '0px',
          }}
        >
          <Stack>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F6FFEC',
                        border: '1px solid',
                        borderColor: '#389E0E',
                        color: '#389E0E',
                        marginTop: '10px',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🔮</span>{' '}
                              Predictive Analytics
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#E6F4FF',
                        border: '1px solid',
                        borderColor: '#0858D9',
                        color: '#0858D9',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🔗</span>{' '}
                              Real-time Supply Chain Insights
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F9F0FF',
                        border: '1px solid',
                        borderColor: '#541DAB',
                        color: '#541DAB',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>😊</span>{' '}
                              Social Sentiment Analysis
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F6FFEC',
                        border: '1px solid',
                        borderColor: '#389E0E',
                        color: '#389E0E',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>♻️</span>{' '}
                              Sustainability Impact Assessment
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#E6F4FF',
                        border: '1px solid',
                        borderColor: '#0858D9',
                        color: '#0858D9',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🔄</span>{' '}
                              Ergonomics Optimization
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F9F0FF',
                        border: '1px solid',
                        borderColor: '#541DAB',
                        color: '#541DAB',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🏭</span>{' '}
                              Manufacturing Audit Adventures
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F6FFEC',
                        border: '1px solid',
                        borderColor: '#389E0E',
                        color: '#389E0E',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🩺</span>{' '}
                              Industrial Health Scorecards
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#E6F4FF',
                        border: '1px solid',
                        borderColor: '#0858D9',
                        color: '#0858D9',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🏆</span>{' '}
                              Industry Sustainability Badges
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F9F0FF',
                        border: '1px solid',
                        borderColor: '#541DAB',
                        color: '#541DAB',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🔍</span>{' '}
                              Supply Chain Explorer Experiences
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F6FFEC',
                        border: '1px solid',
                        borderColor: '#389E0E',
                        color: '#389E0E',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🛠️</span>{' '}
                              Customization Specialization
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#E6F4FF',
                        border: '1px solid',
                        borderColor: '#0858D9',
                        color: '#0858D9',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🚀</span>{' '}
                              Focus on Emerging Technologies
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F9F0FF',
                        border: '1px solid',
                        borderColor: '#541DAB',
                        color: '#541DAB',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>🛠️</span>{' '}
                              Industry Specialization
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingBottom: '0px', textAlign: 'center' }}>
                    <Card
                      style={{
                        background: '#F6FFEC',
                        border: '1px solid',
                        borderColor: '#389E0E',
                        color: '#389E0E',
                      }}
                    >
                      <Card.Body>
                        <Card.Title style={{ margin: '0' }}>
                          {
                            <h4
                              style={{
                                margin: '0',
                                textAlign: 'start',
                                fontSize: '20px',
                              }}
                            >
                              <span style={{ paddingRight: '1.5rem' }}>💯</span>{' '}
                              Future-Oriented Approach
                            </h4>
                          }
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </Stack>
        </div>
      </Drawer>
    </>
  );
}
