import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  CompanyName,
  Industry,
  OtherIndustry,
  industryOptions,
  Department,
} from './Common';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function LargeEnterprise() {
  const [selectedOption, setSelectedOption] = useState('');
  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <CompanyName />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Industry options={industryOptions} onSelect={setSelectedOption} />
        {selectedOption === 'Other' && <OtherIndustry />}
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Department />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ProjectScope />
      </div>
    </div>
  );
}

const ProjectScope = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="project-scope"
              label="Project Scope"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) => GetData('ProjectScope', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
