import Logo from './images/MR3.png';
import './index.css';
import React from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { useState } from 'react';
import { Drawer } from 'antd';
import Sitemap from './Sitemap';

export default function App() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MDBNavbar expand="lg" light bgColor="light" sticky style={{ zIndex: 1 }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href="/home" style={{ marginRight: '0' }}>
            <img
              src={Logo}
              alt="Mining Roots Logo"
              style={{ width: '4vh', height: '4vh' }}
            />
          </MDBNavbarBrand>

          <MDBNavbarBrand href="/home" style={{ marginRight: '0' }}>
            Mining Roots®
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={showDrawer}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
        </MDBContainer>
      </MDBNavbar>
      <Drawer
        id="sitemap"
        title="Sitemap"
        placement="left"
        onClose={onClose}
        open={open}
        size="medium"
      >
        <Sitemap />
      </Drawer>
    </>
  );
}
