import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { industryOptions, Industry, OtherIndustry } from './Common';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function FamilyBusiness() {
  const businessValues = [
    {
      value: 'Under $1 million',
      label: 'Under $1 million',
    },
    {
      value: '$1 million to $5 million',
      label: '$1 million to $5 million',
    },
    {
      value: '$5 million to $10 million',
      label: '$5 million to $10 million',
    },
    {
      value: '$10 million to $50 million',
      label: '$10 million to $50 million',
    },
    {
      value: 'Over $50 million',
      label: 'Over $50 million',
    },
  ];
  const [selectedOption, setSelectedOption] = useState('');
  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <BusinessName />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Industry options={industryOptions} onSelect={setSelectedOption} />
        {selectedOption === 'Other' && <OtherIndustry />}
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ValueOfBusiness options={businessValues} />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ScopeOfBusiness />
      </div>
    </div>
  );
}

const BusinessName = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="business-name"
              label="Business Name"
              variant="outlined"
              onChange={(e) => GetData('BusinessName', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const ValueOfBusiness = (options) => {
  const outerTheme = useTheme();
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="value-of-business"
            select
            label="Value of Business"
            variant="outlined"
            onChange={(e) => GetData('ValueOfBusiness', e.target.value, false)}
          >
            {options.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </div>
    </Box>
  );
};

const ScopeOfBusiness = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="business-scope"
              label="Scope of Business"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) =>
                GetData('ScopeOfBusiness', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
