import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { CCollapse } from '@coreui/react';
import { Tag } from 'antd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../index.css';

export default function Testimonials() {
  return (
    <div>
      <div className="desktop">
        <DesktopTestimonials />
      </div>
      <div
        className="mobile"
        style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
      >
        <MobileTestimonials />
      </div>
      <div
        className="tablet"
        style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
      >
        <MobileTestimonials />
      </div>
    </div>
  );
}

function DesktopTestimonials() {
  return (
    <div className="testimonial">
      <Swiper
        style={{
          '--swiper-navigation-color': '#000',
          '--swiper-pagination-color': '#000',
          paddingTop: '2rem',
        }}
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        loop={true}
        pagination={{
          clickable: true,
          type: 'progressbar',
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/D4D03AQE83t151t8lag/profile-displayphoto-shrink_800_800/0/1703963857632?e=1709769600&v=beta&t=N6Ud7QHs0BJRBqrBrysLpf8UiT3BD74psD3_onnrQz0"
                      />
                      <Link
                        to={'https://www.linkedin.com/in/malcolmdias/'}
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Malcolm Dias
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          India
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    I can't help but smile as I reflect on my time with Mining
                    Roots. It's more than just an organization; it's a community
                    that fosters growth and innovation. My journey started in
                    the final year of college when I interviewed to work on an
                    exciting project. Little did I know, this experience would
                    shape not only my career but also my personal aspirations.
                    <br />
                    <br />
                    Team at Mining Roots, made the entire process feel like a
                    seamless adventure. The unwavering support and guidance were
                    a constant presence, turning what could have been a daunting
                    task into an exciting exploration. Resources and assistance
                    were always within reach, making every step of the journey
                    feel supported. What sets Mining Roots apart is the hands-on
                    approach to learning. They didn't just give us tasks; but
                    immersed us in the world of application, allowing us to
                    think out of the box. It was more than a project; it was a
                    captivating journey that ignited my passion for the field.
                    <br />
                    <br />I wholeheartedly recommend Mining Roots.
                    <br />
                    <br />
                    It's not just about gaining technical skills; it's about
                    discovering your potential in a warm and encouraging
                    environment. Mining Roots isn't just an internship; it's a
                    chance to connect, learn, and grow—a truly human experience
                    that goes beyond the conventional boundaries of professional
                    development.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/C4D03AQHSyU4ft5ch6Q/profile-displayphoto-shrink_800_800/0/1656037608699?e=1710374400&v=beta&t=WUO8SPF7UHJRwc5T9X-ddTwK-HlEiJgoKla86zsU2gU"
                      />
                      <Link
                        to={'https://www.linkedin.com/in/shlokm/'}
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Shlok Marathe
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          Malaysia
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    My internship at Mining Roots was the first ever internship
                    I undertook in my professional career. After completing just
                    a year and a half of my degree, it is safe to say that I
                    didn't have an extensive knowledge of computer science as a
                    whole. Having said that, when I joined Mining Roots, I was
                    greeted by a very welcoming team of talented and
                    enthusiastic students from the same field as me. They were
                    there to help me with any doubt I had, whether it be
                    technical, or administrative. I never felt as if I had to
                    solve my own doubts, there was always someone who I could
                    turn to and have a chat with, and come up with a solution.
                    <br />
                    <br />
                    Throughout the span of the internship, I was able to learn
                    new things such as React, NestJS, and even enhance my
                    understanding of some skills I was aware of, like HTML &
                    CSS. I am certain that these skills under my belt will add
                    value to both me as a person, and my resume. Apart from the
                    technical skills I acquired, this internship also enhanced
                    my soft skills, such as communication, and more importantly,
                    time management.
                    <br />
                    <br />
                    To sum it up, I would highly recommend joining Mining Roots
                    as an intern. One of the most important aspects of learning
                    is applying what you have learnt, and there is no better way
                    to do that than undertaking an internship.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/D4D35AQF8wx4xWF0NJQ/profile-framedphoto-shrink_800_800/0/1665849495788?e=1706284800&v=beta&t=miZpQaxZlpBSmyyt8eNYCR9RzzLWrLTN86cBDSeNqHY"
                      />
                      <Link
                        to={
                          'https://www.linkedin.com/in/saif-deshmukh-0028b5211/'
                        }
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Saif Deshmukh
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          India
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    I started working with mining roots project, we had a
                    properly planned approach throughout the project; we started
                    by researching all aspects, including market study, design,
                    simulation, etc. which made us aware of the technologies
                    used and helped us take calculated decisions whenever
                    necessary. Working under an industry professional, we also
                    learnt and experienced how startups and corporates function
                    and how to handle stress. Overall, it was a great
                    experience, I probably had one of the best-researched and
                    executed project.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/D4D03AQFwAZQ9quufNA/profile-displayphoto-shrink_800_800/0/1683375144818?e=1709769600&v=beta&t=SLVmejzZ5RdWWQtVXogYj71oV-xXz9v0jjPoIE5ixBA"
                      />
                      <Link
                        to={
                          'https://www.linkedin.com/in/jayesh-kapadnis-350b26238/'
                        }
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Jayesh Kapadnis
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          India
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    During my tenure at Mining Roots, for 7 months, I had the
                    opportunity to contribute to various projects and task. The
                    guided approach was driven by a passion for learning and a
                    proactive problem-solving attitude that enabled me to make
                    significant contributions to the team. <br />
                    <br />I actively engaged in collaborative endeavors,
                    leveraging my skills and fostering a positive environment.
                    My dedication and efforts were acknowledged, leading to a
                    promotion to the role of 'Research Team Manager' within a
                    brief three-month period. The experience at Mining Roots not
                    only honed my technical skills but also provided me with
                    invaluable insights into project management and leadership.
                    I am grateful for the opportunities that allowed me to grow
                    both professionally and personally during my tenure. <br />
                    <br />I am excited to carry forward the knowledge gained and
                    the valuable experiences from Mining Roots into my future
                    endeavors, aiming to contribute effectively to innovative
                    and impactful projects in the field.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/D4D03AQE6QfxReu8v0g/profile-displayphoto-shrink_800_800/0/1688794905562?e=1709769600&v=beta&t=aP7u1i2RQB1JwfD_pTyg0ZeyVmgC5UP85wXntkNdkVY"
                      />
                      <Link
                        to={
                          'https://www.linkedin.com/in/mihir-mhatre-045a13256/'
                        }
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Mihir Mhatre
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          India
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    I would like to thank mining roots for their guidance and
                    support throughout the internship. Mentorship has been
                    instrumental in helping me navigate the challenges of the
                    workplace and gain a deeper understanding of the industry.
                    Insights and feedback have not only enhanced my technical
                    skills but have also shaped my approach to problem-solving
                    and decision-making. <br />
                    <br />
                    With the diverse range of approaches, I had the chance to
                    work on tasks provided to me with a well-rounded view of the
                    company's operations. The hands-on experience allowed me to
                    apply theoretical knowledge from my academic studies and
                    gave me a practical understanding of how concepts translate
                    into real-world scenarios. I am confident that the skills
                    and knowledge I acquired will serve as a solid foundation
                    for my future endeavors.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/D4D03AQFNCKrV7p5Eug/profile-displayphoto-shrink_800_800/0/1703337037996?e=1709769600&v=beta&t=kinUvKIRGCqyUeUF8h856f3ocQCfgHGtyJLRyhYEg5M"
                      />
                      <Link
                        to={
                          'https://www.linkedin.com/in/sanskruti-palekar-38517322a/'
                        }
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Sanskruti Palekar
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          India
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    Embarking on my internship journey, I learned React.js, UI
                    and the art of blueprints while understanding product
                    planning and management. Through challenges, I honed time
                    and pressure management skills, becoming more confident in
                    my skillset and practical knowledge. I'd recommend Mining
                    Roots to anyone who wants to learn from industry expert and
                    start their journey in their field of interests.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '40vh',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems="center"
                      style={{
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                        paddingLeft: '2rem',
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 60, height: 60 }}
                        src="https://media.licdn.com/dms/image/C4E03AQFHAJIGTvMGNg/profile-displayphoto-shrink_800_800/0/1620817484847?e=1709769600&v=beta&t=XxfuhT0_wamBxm6ENjLWodoURcBNmadd9ZYt7sLmC3M"
                      />
                      <Link
                        to={
                          'https://www.linkedin.com/in/sumedhchinchmalatpure/'
                        }
                        target="_blank"
                      >
                        <h1
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '25px',
                          }}
                        >
                          Sumedh Chinchmalatpure
                        </h1>
                        <h4
                          style={{
                            margin: '0px',
                            display: 'flex',
                            fontSize: '20px',
                          }}
                        >
                          India
                        </h4>
                      </Link>
                    </Stack>
                  }
                </Card.Title>
                <Card.Text>
                  <p
                    className="testimonial-text"
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    The best part was the humongous amount of research we did
                    even before we started working on the project, the most
                    important part about building a product or a project is to
                    understand the feasibility and impact, the learning moment
                    for me was determining the above factors and making a
                    decision. <br />
                    <br />
                    The most hands on experience i had was when we were out in
                    the field determining the characteristics and design
                    elements of the project. This internship made me a better
                    thinker and analyst in terms of doing a vast background
                    research and risk analysis of a project thus, making me more
                    ready and comfortable for a challenge.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

function MobileTestimonials() {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <div
        style={{
          minWidth: '40vw',
          height: '2rem',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          paddingTop: '2rem',
          paddingBottom: '5rem',
        }}
      >
        <Tag
          onClick={() => setVisible(!visible)}
          style={{
            width: '40vw',
            height: '3rem',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1rem',
            color: '#541DAB',
            borderColor: '#541DAB',
            background: '#F9F0FF',
          }}
        >
          Testimonials{' '}
          <KeyboardArrowDownIcon
            className={`arrow-icon-to-animate ${visible ? 'rotated' : ''}`}
          />
        </Tag>
      </div>
      <CCollapse visible={visible}>
        <div className="testimonial">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            loop={true}
            pagination={{
              clickable: true,
              type: 'progressbar',
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            style={{ paddingTop: '2rem' }}
          >
            <SwiperSlide>
              <div style={{ paddingBottom: '3rem' }}>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/D4D03AQE83t151t8lag/profile-displayphoto-shrink_800_800/0/1703963857632?e=1709769600&v=beta&t=N6Ud7QHs0BJRBqrBrysLpf8UiT3BD74psD3_onnrQz0"
                          />
                          <Link
                            to={'https://www.linkedin.com/in/malcolmdias/'}
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                              }}
                            >
                              Malcolm Dias
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              India
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        I can't help but smile as I reflect on my time with
                        Mining Roots. It's more than just an organization; it's
                        a community that fosters growth and innovation. My
                        journey started in the final year of college when I
                        interviewed to work on an exciting project. Little did I
                        know, this experience would shape not only my career but
                        also my personal aspirations.
                        <br />
                        <br />
                        Team at Mining Roots, made the entire process feel like
                        a seamless adventure. The unwavering support and
                        guidance were a constant presence, turning what could
                        have been a daunting task into an exciting exploration.
                        Resources and assistance were always within reach,
                        making every step of the journey feel supported. What
                        sets Mining Roots apart is the hands-on approach to
                        learning. They didn't just give us tasks; but immersed
                        us in the world of application, allowing us to think out
                        of the box. It was more than a project; it was a
                        captivating journey that ignited my passion for the
                        field.
                        <br />
                        <br />I wholeheartedly recommend Mining Roots.
                        <br />
                        <br />
                        It's not just about gaining technical skills; it's about
                        discovering your potential in a warm and encouraging
                        environment. Mining Roots isn't just an internship; it's
                        a chance to connect, learn, and grow—a truly human
                        experience that goes beyond the conventional boundaries
                        of professional development.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/C4D03AQHSyU4ft5ch6Q/profile-displayphoto-shrink_800_800/0/1656037608699?e=1710374400&v=beta&t=WUO8SPF7UHJRwc5T9X-ddTwK-HlEiJgoKla86zsU2gU"
                          />
                          <Link
                            to={'https://www.linkedin.com/in/shlokm/'}
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                              }}
                            >
                              Shlok Marathe
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              Malaysia
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        My internship at Mining Roots was the first ever
                        internship I undertook in my professional career. After
                        completing just a year and a half of my degree, it is
                        safe to say that I didn't have an extensive knowledge of
                        computer science as a whole. Having said that, when I
                        joined Mining Roots, I was greeted by a very welcoming
                        team of talented and enthusiastic students from the same
                        field as me. They were there to help me with any doubt I
                        had, whether it be technical, or administrative. I never
                        felt as if I had to solve my own doubts, there was
                        always someone who I could turn to and have a chat with,
                        and come up with a solution.
                        <br />
                        <br />
                        Throughout the span of the internship, I was able to
                        learn new things such as React, NestJS, and even enhance
                        my understanding of some skills I was aware of, like
                        HTML & CSS. I am certain that these skills under my belt
                        will add value to both me as a person, and my resume.
                        Apart from the technical skills I acquired, this
                        internship also enhanced my soft skills, such as
                        communication, and more importantly, time management.
                        <br />
                        <br />
                        To sum it up, I would highly recommend joining Mining
                        Roots as an intern. One of the most important aspects of
                        learning is applying what you have learnt, and there is
                        no better way to do that than undertaking an internship.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ paddingBottom: '3rem' }}>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/D4D35AQF8wx4xWF0NJQ/profile-framedphoto-shrink_800_800/0/1665849495788?e=1706284800&v=beta&t=miZpQaxZlpBSmyyt8eNYCR9RzzLWrLTN86cBDSeNqHY"
                          />
                          <Link
                            to={
                              'https://www.linkedin.com/in/saif-deshmukh-0028b5211/'
                            }
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                              }}
                            >
                              Saif Deshmukh
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              India
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        I started working with mining roots project, we had a
                        properly planned approach throughout the project; we
                        started by researching all aspects, including market
                        study, design, simulation, etc. which made us aware of
                        the technologies used and helped us take calculated
                        decisions whenever necessary. Working under an industry
                        professional, we also learnt and experienced how
                        startups and corporates function and how to handle
                        stress. Overall, it was a great experience, I probably
                        had one of the best-researched and executed project.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ paddingBottom: '3rem' }}>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/D4D03AQFwAZQ9quufNA/profile-displayphoto-shrink_800_800/0/1683375144818?e=1709769600&v=beta&t=SLVmejzZ5RdWWQtVXogYj71oV-xXz9v0jjPoIE5ixBA"
                          />
                          <Link
                            to={
                              'https://www.linkedin.com/in/jayesh-kapadnis-350b26238/'
                            }
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                              }}
                            >
                              Jayesh Kapadnis
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              India
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        During my tenure at Mining Roots, for 7 months, I had
                        the opportunity to contribute to various projects and
                        task. The guided approach was driven by a passion for
                        learning and a proactive problem-solving attitude that
                        enabled me to make significant contributions to the
                        team. <br />
                        <br />I actively engaged in collaborative endeavors,
                        leveraging my skills and fostering a positive
                        environment. My dedication and efforts were
                        acknowledged, leading to a promotion to the role of
                        'Research Team Manager' within a brief three-month
                        period. The experience at Mining Roots not only honed my
                        technical skills but also provided me with invaluable
                        insights into project management and leadership. I am
                        grateful for the opportunities that allowed me to grow
                        both professionally and personally during my tenure.{' '}
                        <br />
                        <br />I am excited to carry forward the knowledge gained
                        and the valuable experiences from Mining Roots into my
                        future endeavors, aiming to contribute effectively to
                        innovative and impactful projects in the field.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ paddingBottom: '3rem' }}>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/D4D03AQE6QfxReu8v0g/profile-displayphoto-shrink_800_800/0/1688794905562?e=1709769600&v=beta&t=aP7u1i2RQB1JwfD_pTyg0ZeyVmgC5UP85wXntkNdkVY"
                          />
                          <Link
                            to={
                              'https://www.linkedin.com/in/mihir-mhatre-045a13256/'
                            }
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                              }}
                            >
                              Mihir Mhatre
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              India
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        I would like to thank mining roots for their guidance
                        and support throughout the internship. Mentorship has
                        been instrumental in helping me navigate the challenges
                        of the workplace and gain a deeper understanding of the
                        industry. Insights and feedback have not only enhanced
                        my technical skills but have also shaped my approach to
                        problem-solving and decision-making. <br />
                        <br />
                        With the diverse range of approaches, I had the chance
                        to work on tasks provided to me with a well-rounded view
                        of the company's operations. The hands-on experience
                        allowed me to apply theoretical knowledge from my
                        academic studies and gave me a practical understanding
                        of how concepts translate into real-world scenarios. I
                        am confident that the skills and knowledge I acquired
                        will serve as a solid foundation for my future
                        endeavors.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ paddingBottom: '3rem' }}>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/D4D03AQFNCKrV7p5Eug/profile-displayphoto-shrink_800_800/0/1703337037996?e=1709769600&v=beta&t=kinUvKIRGCqyUeUF8h856f3ocQCfgHGtyJLRyhYEg5M"
                          />
                          <Link
                            to={
                              'https://www.linkedin.com/in/sanskruti-palekar-38517322a/'
                            }
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                              }}
                            >
                              Sanskruti Palekar
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              India
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        Embarking on my internship journey, I learned React.js,
                        UI and the art of blueprints while understanding product
                        planning and management. Through challenges, I honed
                        time and pressure management skills, becoming more
                        confident in my skillset and practical knowledge. I'd
                        recommend Mining Roots to anyone who wants to learn from
                        industry expert and start their journey in their field
                        of interests.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ paddingBottom: '3rem' }}>
                <Card
                  border="dark"
                  style={{
                    minHeight: '40vh',
                    background: '#FAFAFA',
                    borderRadius: '4rem',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {
                        <Stack
                          direction={'row'}
                          spacing={5}
                          alignItems="center"
                          style={{
                            paddingBottom: '2rem',
                            paddingTop: '1rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 60, height: 60 }}
                            src="https://media.licdn.com/dms/image/C4E03AQFHAJIGTvMGNg/profile-displayphoto-shrink_800_800/0/1620817484847?e=1709769600&v=beta&t=XxfuhT0_wamBxm6ENjLWodoURcBNmadd9ZYt7sLmC3M"
                          />
                          <Link
                            to={
                              'https://www.linkedin.com/in/sumedhchinchmalatpure/'
                            }
                            target="_blank"
                          >
                            <h1
                              style={{
                                margin: '0px',
                                display: 'flex',
                                textAlign: 'start',
                                fontSize: '1.5rem',
                              }}
                            >
                              Sumedh Chinchmalatpure
                            </h1>
                            <h4
                              style={{
                                margin: '0px',
                                display: 'flex',
                                fontSize: '20px',
                              }}
                            >
                              India
                            </h4>
                          </Link>
                        </Stack>
                      }
                    </Card.Title>
                    <Card.Text>
                      <p
                        className="testimonial-text"
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        The best part was the humongous amount of research we
                        did even before we started working on the project, the
                        most important part about building a product or a
                        project is to understand the feasibility and impact, the
                        learning moment for me was determining the above factors
                        and making a decision. <br />
                        <br />
                        The most hands on experience i had was when we were out
                        in the field determining the characteristics and design
                        elements of the project. This internship made me a
                        better thinker and analyst in terms of doing a vast
                        background research and risk analysis of a project thus,
                        making me more ready and comfortable for a challenge.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </CCollapse>
    </div>
  );
}
