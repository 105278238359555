import React, { useState } from 'react';
import { Drawer } from 'antd';
import ContactUs from './contact/ContactUs';
import Card from 'react-bootstrap/Card';
import Button from '@mui/material/Button';

export default function ContactDrawer() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ zIndex: '3' }}>
      <Card
        border="dark"
        style={{
          maxWidth: '32rem',
          minHeight: '9.67rem',
          minWidth: '17rem',
          cursor: 'pointer',
        }}
        onClick={showDrawer}
      >
        <Card.Body>
          <Card.Title>Contact Us</Card.Title>
        </Card.Body>
      </Card>
      <Drawer
        title="Contact Us Form"
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
        id="contact"
      >
        <ContactUs />
      </Drawer>
    </div>
  );
}

export function ContactCard() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ paddingRight: '25%', paddingLeft: '25%' }}>
      <Card
        onClick={showDrawer}
        style={{
          cursor: 'pointer',
          borderRadius: '40px',
          background: '#F6FFEC',
          border: '1px solid',
          color: '#3CA015',
          borderColor: '#3CA015',
        }}
      >
        <Card.Body style={{ borderRadius: '40px', borderColor: '#3CA015' }}>
          <Card.Title
            style={{
              whiteSpace: 'nowrap',
              marginBottom: '0px',
              textAlign: 'center',
              alignSelf: 'center',
              borderColor: '#3CA015',
            }}
          >
            {<span style={{ margin: '0', fontSize: '15px' }}>CONTACT US</span>}
          </Card.Title>
        </Card.Body>
      </Card>
      <Drawer
        title="Contact Us Form"
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
        id="contact"
      >
        <ContactUs />
      </Drawer>
    </div>
  );
}

export function ContactDrawerButton() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={showDrawer}
        style={{
          whiteSpace: 'nowrap',
          background: '#F0F5FF',
          color: '#1E39C3',
          border: '1px solid',
          borderColor: '#1E39C3',
          borderRadius: '5rem',
        }}
      >
        <span>Contact Us</span>
      </Button>
      <Drawer
        title="Contact Us Form"
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
        id="contact"
      >
        <ContactUs />
      </Drawer>
    </div>
  );
}
