import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Institute, Department } from './Common';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function CollegeUniversity() {
  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Institute />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Department />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ResearchFocusArea />
      </div>
    </div>
  );
}

const ResearchFocusArea = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="research-focus-area"
              label="Research Focus Area"
              variant="outlined"
              onChange={(e) =>
                GetData('ResearchFocusArea', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
