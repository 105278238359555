// import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/css';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import '../../index.css';

// // import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../../index.css';

// import required modules

export default function App() {
  return (
    <div style={{ height: '20vh', paddingLeft: '10px', paddingRight: '10px' }}>
      <Swiper
        // direction={'vertical'}
        centeredSlides={true}
        style={{
          '--swiper-navigation-color': '#000',
          '--swiper-pagination-color': '#000',
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          type: 'progressbar',
        }}
        loop={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          {/* Transform your visionary ideas into reality. */}
          <Card
            id="benefits"
            border="dark"
            style={{
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Transform your visionary ideas into reality.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              //minHeight: '80vh',
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Develop innovative and disruptive ideas that address
                      critical challenges.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              //minHeight: '80vh',
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Increase your chances of success by ensuring your concept
                      is feasible, viable, and impactful.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              //minHeight: '80vh',
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
              marginBottom: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Reduce risks and uncertainties through comprehensive
                      evaluation and mitigation strategies.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              //minHeight: '80vh',
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Gain valuable insights into market demand, competitor
                      landscape, and potential returns on investment.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              //minHeight: '80vh',
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Attract investors and partners by demonstrating the
                      potential of your concept.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card
            id="benefits"
            border="dark"
            style={{
              //minHeight: '80vh',
              cursor: 'pointer',
              background: '#FAFAFA',
              borderRadius: '4rem',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            <Card.Body>
              <Card.Title>
                {
                  <div>
                    <h4 style={{ fontSize: '20px' }}>
                      Create a culture of innovation within your organization.
                    </h4>
                  </div>
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
