import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import ContactUs from './contact/ContactUs';
import { useState } from 'react';
import { Drawer } from 'antd';

const App = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };
  const treeData = [
    {
      title: (
        <a href="/home" style={{ fontSize: '1rem', color: 'black' }}>
          🏠 Home
        </a>
      ),
      key: '1',
      onSelect: {},
    },
    {
      title: (
        <a href="/" style={{ fontSize: '1rem', color: 'black' }}>
          🛍️ Offerings
        </a>
      ),
      key: '2',
      children: [
        {
          title: (
            <a
              href="/offerings/research-and-development"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🔍 Research & Development
            </a>
          ),
          key: '2.1',
          children: [
            {
              title: (
                <a
                  href="/offerings/research-and-development?id=rd-unique-values"
                  style={{ fontSize: '1rem', color: 'black' }}
                >
                  🔬 R&D Unique Values
                </a>
              ),
            },
          ],
        },
        {
          title: (
            <a
              href="/offerings/industry-analysis"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              📊 Industry Analysis
            </a>
          ),
          key: '2.2',
          children: [
            {
              title: (
                <a
                  href="/offerings/industry-analysis?id=ia-unique-values"
                  style={{ fontSize: '1rem', color: 'black' }}
                >
                  📈 IA Unique Values
                </a>
              ),
            },
          ],
        },
        {
          title: (
            <a
              href="/offerings/concept-building-and-evaluation"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🛠️ Concept Building & Evaluation
            </a>
          ),
          key: '2.3',
          children: [
            {
              title: (
                <a
                  href="/offerings/concept-building-and-evaluation?id=cbe-unique-values"
                  style={{ fontSize: '1rem', color: 'black' }}
                >
                  🧰 CBE Unique Values
                </a>
              ),
            },
          ],
        },
        {
          title: (
            <a
              href="/offerings/technology-consultancy"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              💻 Technology Consultancy
            </a>
          ),
          key: '2.4',
          children: [
            {
              title: (
                <a
                  href="/offerings/technology-consultancy?id=tc-unique-values"
                  style={{ fontSize: '1rem', color: 'black' }}
                >
                  ⚙️ TC Unique Values
                </a>
              ),
            },
          ],
        },
      ],
    },
    {
      title: (
        <a href="/who-are-you" style={{ fontSize: '1rem', color: 'black' }}>
          👤 Who are you?
        </a>
      ),
      key: '3',
      children: [
        {
          title: (
            <a
              href="/who-are-you?id=startup"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🚀 Startup
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=small-medium-company"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🏢 Small Medium Company
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=big-firm"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🏭 Big Firm
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=family-business"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              👪 Family Business
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=student"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🎓 Student
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=college-and-university"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🏫 College & University
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=solo-founder"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              💼 Solo Founder
            </a>
          ),
        },
        {
          title: (
            <a
              href="/who-are-you?id=side-hustler"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              💡 Side Hustler
            </a>
          ),
        },
      ],
    },
    {
      title: (
        <p
          onClick={() => showDrawer()}
          style={{ fontSize: '1rem', color: 'black', marginBottom: '0' }}
        >
          📞 Contact Us
        </p>
      ),
      key: '4',
    },
    {
      title: (
        <a
          href="https://mining-roots.notion.site/Portfolio-Mining-Roots-509e0efcea954cbdaafae396019df6a1?pvs=4"
          style={{ fontSize: '1rem', color: 'black' }}
        >
          🗃️ Portfolio
        </a>
      ),
      key: '5',
    },
    {
      title: (
        <a href="/career" style={{ fontSize: '1rem', color: 'black' }}>
          📣 Testimonials
        </a>
      ),
      key: '6',
    },
    {
      title: (
        <a href="/career" style={{ fontSize: '1rem', color: 'black' }}>
          🌐 Career
        </a>
      ),
      key: '7',
      children: [
        {
          title: (
            <a
              href="/career?id=job"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              💼 Jobs
            </a>
          ),
        },
        {
          title: (
            <a
              href="/career?id=internship"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🎓 Internship
            </a>
          ),
        },
        {
          title: (
            <a
              href="/career?id=partnership"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              🤝 Partnership
            </a>
          ),
        },
        {
          title: (
            <a
              href="/career?id=experts-freelancing"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              👨‍💻 Experts Freelancing
            </a>
          ),
        },
      ],
    },
    {
      title: (
        <a href="/" style={{ fontSize: '1rem', color: 'black' }}>
          🔗 Social Presence
        </a>
      ),
      key: '8',
      children: [
        {
          title: (
            <a
              href="https://www.linkedin.com/company/mining-roots/"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: '1rem', color: 'black' }}
            >
              LinkedIn
            </a>
          ),
        },
      ],
    },
  ];
  return (
    <>
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={[
          '2',
          '2.1',
          '2.2',
          '2.3',
          '2.4',
          '3',
          '6',
          '7',
          '8',
        ]}
        onSelect={onSelect}
        treeData={treeData}
      />
      <Drawer
        title="Contact Us Form"
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
        id="contact"
      >
        <ContactUs />
      </Drawer>
    </>
  );
};
export default App;
