import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Institute } from './Common';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function Student() {
  const [selectedOption, setSelectedOption] = useState('');
  const yearOptions = [
    {
      value: 'Freshman',
      label: 'Freshman',
    },
    {
      value: 'Sophomore',
      label: 'Sophomore',
    },
    {
      value: 'Junior',
      label: 'Junior',
    },
    {
      value: 'Senior',
      label: 'Senior',
    },
    {
      value: 'Graduate Student',
      label: 'Graduate Student',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <YearOfStudy options={yearOptions} onSelect={setSelectedOption} />
        {selectedOption === 'Other' && <OtherYear />}
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Major />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Institute />
      </div>
    </div>
  );
}

const YearOfStudy = ({ options, onSelect }) => {
  const outerTheme = useTheme();
  function select(name, e, f) {
    f(e);
    GetData(name, e, false);
  }
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="year-of-study"
            select
            label="Year of Study"
            variant="outlined"
            onChange={(e) => select('YearOfStudy', e.target.value, onSelect)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </Box>
    </div>
  );
};

const OtherYear = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="other-year"
              label="Other Year"
              variant="outlined"
              onChange={(e) => GetData('YearOfStudy', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const Major = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="major"
              label="Major/Field of Study"
              variant="outlined"
              onChange={(e) => GetData('Major', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
