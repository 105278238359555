import React, { useState } from 'react';
import { CCollapse } from '@coreui/react';
import SideCards from './SideCards';
import { Tag } from 'antd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function MobileSideCard() {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <div
        style={{
          minWidth: '40vw',
          height: '2rem',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        <Tag
          color="#00E1AD"
          onClick={() => {
            setVisible(!visible);
          }}
          style={{
            width: '40vw',
            height: '3rem',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1rem',
            color: '#389E0E',
            borderColor: '#389E0E',
            background: '#F6FFEC',
          }}
        >
          Expertise{' '}
          <KeyboardArrowDownIcon
            className={`arrow-icon-to-animate ${visible ? 'rotated' : ''}`}
          />
        </Tag>
      </div>
      <CCollapse visible={visible}>
        <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <SideCards />
        </div>
      </CCollapse>
    </div>
  );
}
