import React from 'react';
import { Row, Col } from 'antd';
import image1 from '../images/Career.webp';
import Testimonials from './Testimoials';
import { Tabs } from 'antd';
import Card from 'react-bootstrap/Card';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import '../index.css';

export default function Career() {
  return (
    <div className="split-container">
      <div className="only-section">
        <ImageAndText />
        <Testimonials />

        <CareerSelection />
      </div>
    </div>
  );
}

function ImageAndText() {
  return (
    <div>
      <Row>
        <Col sm={8}>
          <div style={{ textAlign: 'center' }}>
            <img
              src={image1}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={16}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{ paddingBottom: '20px', fontSize: '32px' }}
            >
              Explore Exciting Opportunities with Mining Roots
            </h1>
            <h2
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, we believe in building a team of dynamic
              individuals who are passionate about driving innovation and
              contributing to technological advancements. Whether you're a job
              seeker, an aspiring partner looking to collaborate and invest, an
              internship applicant eager to gain hands-on experience, or a
              seasoned expert seeking freelancing opportunities, Mining Roots
              offers a diverse range of opportunities to fuel your career
              growth.
            </h2>
            <h2
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, we believe in building a team of dynamic
              individuals who are passionate about driving innovation and
              contributing to technological advancements. Whether you're a job
              seeker, an aspiring partner looking to collaborate and invest, an
              internship applicant eager to gain hands-on experience, or a
              seasoned expert seeking freelancing opportunities, Mining Roots
              offers a diverse range of opportunities to fuel your career
              growth.
            </h2>
          </div>
        </Col>
      </Row>
      <h2
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
        }}
      >
        At Mining Roots, we believe in building a team of dynamic individuals
        who are passionate about driving innovation and contributing to
        technological advancements. Whether you're a job seeker, an aspiring
        partner looking to collaborate and invest, an internship applicant eager
        to gain hands-on experience, or a seasoned expert seeking freelancing
        opportunities, Mining Roots offers a diverse range of opportunities to
        fuel your career growth.
      </h2>
      <div className="career-values">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h2
            style={{
              fontSize: '25px',
              paddingTop: '2rem',
              textAlign: 'start',
              textWrap: 'nowrap',
              fontWeight: '600',
            }}
          >
            Remember, we value:
          </h2>
        </Stack>
        <div className="job">
          <Stack
            direction={'row'}
            spacing={3}
            alignItems="center"
            style={{
              paddingBottom: '1rem',
            }}
          >
            <h2
              style={{
                fontSize: '23px',
                textAlign: 'start',
              }}
            >
              ❤️ Passion and Drive
            </h2>
          </Stack>
          <h4 style={{ fontSize: '20px' }}>
            A relentless pursuit of excellence and a desire to push the
            boundaries of what's possible.
          </h4>
        </div>
        <div className="job">
          <Stack
            direction={'row'}
            spacing={3}
            alignItems="center"
            style={{
              paddingBottom: '1rem',
            }}
          >
            <h2
              style={{
                fontSize: '23px',
                textAlign: 'start',
              }}
            >
              🧠 Intellectual Curiosity
            </h2>
          </Stack>
          <h4 style={{ fontSize: '20px' }}>
            A thirst for knowledge and a willingness to explore uncharted
            territories.
          </h4>
        </div>
        <div className="job">
          <Stack
            direction={'row'}
            spacing={3}
            alignItems="center"
            style={{
              paddingBottom: '1rem',
            }}
          >
            <h2
              style={{
                fontSize: '23px',
                textAlign: 'start',
              }}
            >
              🤝 Collaborative spirit
            </h2>
          </Stack>
          <h4 style={{ fontSize: '20px' }}>
            The ability to work seamlessly with diverse teams and foster open
            communication.
          </h4>
        </div>
        <div className="job">
          <Stack
            direction={'row'}
            spacing={3}
            alignItems="center"
            style={{
              paddingBottom: '1rem',
            }}
          >
            <h2
              style={{
                fontSize: '23px',
                textAlign: 'start',
              }}
            >
              💡 Will to Innovate
            </h2>
          </Stack>
          <h4 style={{ fontSize: '20px' }}>
            A passion for creative problem-solving and contributing to
            groundbreaking solutions.
          </h4>
        </div>
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h2
            style={{
              fontSize: '25px',
              paddingTop: '3rem',
              paddingBottom: '2rem',
              textAlign: 'center',
              fontWeight: '600',
            }}
          >
            Together, let's unlock the potential that lies within.
          </h2>
        </Stack>
      </div>
    </div>
  );
}

const CareerSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('id');
  let tabNumber = '1';
  switch (id) {
    case 'jobs':
      tabNumber = '1';
      break;
    case 'internship':
      tabNumber = '2';
      break;
    case 'partnership':
      tabNumber = '3';
      break;
    case 'experts-freelancing':
      tabNumber = '4';
      break;
    default:
      tabNumber = '1';
      break;
  }
  const items = [
    {
      key: '1',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'jobs' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Job
        </h4>
      ),
      children: (
        <div className="split-container">
          <div className="only-section-career">
            <Job />
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'internship' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Internship
        </h4>
      ),
      children: (
        <div className="split-container">
          <div className="only-section-career">
            <Internship />
          </div>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(`?${new URLSearchParams({ id: 'partnership' })}`)
          }
          style={{ fontSize: '20px' }}
        >
          Partnership
        </h4>
      ),
      children: (
        <div className="split-container">
          <div className="only-section-career">
            <Partnership />
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <h4
          className="tabs-text"
          onClick={() =>
            setSearchParams(
              `?${new URLSearchParams({ id: 'experts-freelancing' })}`
            )
          }
          style={{ fontSize: '20px' }}
        >
          Expert Freelancing
        </h4>
      ),
      children: (
        <div className="split-container">
          <div className="only-section-career">
            <ExpertFreelancing />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Tabs
        centered
        type="card"
        className="desktop"
        defaultActiveKey={tabNumber}
        tabPosition="top"
        items={items}
      />
      <Tabs
        centered
        type="card"
        className="tablet"
        defaultActiveKey={tabNumber}
        tabPosition="top"
        items={items}
      />
      <Tabs
        className="mobile"
        defaultActiveKey={tabNumber}
        tabPosition="top"
        type="card"
        style={{
          height: '100%',
          position: 'sticky',
        }}
        items={items}
      />
    </div>
  );
};

const Job = () => {
  return (
    <div className="jobs">
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🚀</h1>
          <h2 style={{ fontSize: '25px' }}>Unleash Your Potential</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Join us on a journey of continuous learning and growth. At Mining
          Roots, we value talent, creativity, and a commitment to excellence. As
          a member of our team, you'll have the chance to work on cutting-edge
          projects, collaborate with industry experts, and contribute to
          solutions that have a real impact.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>💼</h1>
          <h2 style={{ fontSize: '25px' }}>Join Our Dynamic Team</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          We seek passionate individuals with diverse skillsets who thrive in a
          collaborative environment. From research scientists and technology
          consultants to industrial analysts and project managers, discover
          positions that challenge your intellect and contribute to
          industry-altering solutions.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🏆</h1>
          <h2 style={{ fontSize: '25px' }}>Make a Lasting Impact</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          At Mining Roots, your work doesn't go unnoticed. You'll be directly
          involved in projects that shape the future of various industries,
          leaving a tangible mark on progress and innovation.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🌱</h1>
          <h2 style={{ fontSize: '25px' }}>Continuous Learning & Growth</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          We champion professional development. Be mentored by industry experts,
          access cutting-edge training programs, and unlock your full potential
          in a culture that constantly pushes boundaries.
        </h4>
      </div>
      <Card
        border="dark"
        style={{
          minHeight: '30vh',
          background: '#FAFAFA',
          borderRadius: '4rem',
        }}
      >
        <Card.Body>
          <Card.Title>
            {
              <div>
                <h2
                  style={{
                    paddingBottom: '2rem',
                    color: '#0087ff',
                    paddingTop: '2rem',
                    fontSize: '25px',
                  }}
                >
                  How to Apply?
                </h2>
              </div>
            }
          </Card.Title>
          <Card.Text>
            <div>
              <ul>
                <li className="job-list">
                  Mail to us at{' '}
                  <a href="mailto:team@mining-roots.com">
                    team@mining-roots.com
                  </a>{' '}
                  in below-specified format
                </li>
                <li className="job-list">
                  Subject :- Job Application (your name)
                </li>
                <li className="job-list">Include the following details:</li>
              </ul>
              <ol style={{ marginLeft: '2rem', paddingLeft: '2rem' }}>
                <li className="job-list">Full Name</li>
                <li className="job-list">Phone Number</li>
                <li className="job-list">LinkedIn Profile</li>
                <li className="job-list">Current Job Title (if applicable)</li>
                <li className="job-list">Years of Experience</li>
                <li className="job-list">Target Job Position</li>
                <li className="job-list">
                  Why do you want to join Mining Roots, and what unique skills
                  or perspectives can you bring to our team?
                </li>
              </ol>
              <ul>
                <li className="job-list">Attach a PDF of your latest resume</li>
              </ul>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

const Internship = () => {
  return (
    <div className="jobs">
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🎓</h1>
          <h2 style={{ fontSize: '25px' }}>Hands-On Learning</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Kickstart your career by gaining practical experience with Mining
          Roots. Our internship programs are designed to provide aspiring
          professionals with hands-on exposure to real-world projects,
          mentorship from industry leaders, and a chance to contribute to
          meaningful initiatives.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🛠️</h1>
          <h2 style={{ fontSize: '25px' }}>Develop Invaluable Skills</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Gain practical experience in research, analysis, concept building, and
          more. Hone your technical skills, build communication and
          collaboration expertise, and prepare yourself for a thriving career in
          a dynamic field.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🌟</h1>
          <h2 style={{ fontSize: '25px' }}>Leave Your Mark</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Your contributions at Mining Roots won't just be theoretical. Get
          involved in real-world projects, make a difference, and see the
          potential impact of your work firsthand.
        </h4>
      </div>
      <Card
        border="dark"
        style={{
          minHeight: '30vh',
          background: '#FAFAFA',
          borderRadius: '4rem',
        }}
      >
        <Card.Body>
          <Card.Title>
            {
              <div>
                <h2
                  style={{
                    paddingBottom: '2rem',
                    color: '#0087ff',
                    paddingTop: '2rem',
                    fontSize: '25px',
                  }}
                >
                  How to Apply?
                </h2>
              </div>
            }
          </Card.Title>
          <Card.Text>
            <div>
              <ul>
                <li className="job-list">
                  Mail to us at{' '}
                  <a href="mailto:team@mining-roots.com">
                    team@mining-roots.com
                  </a>{' '}
                  in below-specified format
                </li>
                <li className="job-list">
                  Subject :- Internship Application (your name)
                </li>
                <li className="job-list">Include the following details:</li>
              </ul>
              <ol style={{ marginLeft: '2rem', paddingLeft: '2rem' }}>
                <li className="job-list">Full Name</li>
                <li className="job-list">Phone Number</li>
                <li className="job-list">LinkedIn Profile</li>
                <li className="job-list">Current College/University</li>
                <li className="job-list">Major/Area of Study</li>
                <li className="job-list">Expected Graduation Date</li>
                <li className="job-list">Desired Internship Area</li>
                <li className="job-list">
                  Why do you want to join Mining Roots, and what unique skills
                  or perspectives can you bring to our team?
                </li>
              </ol>
              <ul>
                <li className="job-list">Attach a PDF of your latest resume</li>
              </ul>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

const Partnership = () => {
  return (
    <div className="jobs">
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🤝</h1>
          <h2 style={{ fontSize: '25px' }}>Strategic Alliances</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Mining Roots is open to forging strategic partnerships with
          like-minded organizations and individuals. Whether you're looking to
          collaborate on specific projects or invest in innovative solutions, we
          welcome the opportunity to explore mutually beneficial partnerships.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🌍</h1>
          <h2 style={{ fontSize: '25px' }}>Co-Create the Future</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Reach out to our partnership team to discuss potential collaboration
          opportunities. We believe in the power of collective expertise to
          drive meaningful change and advancement. Become an active participant
          in shaping the future. Through partnerships, you can drive real
          change, define new standards, and leave a lasting legacy on the
          industry you're passionate about.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>💰</h1>
          <h2 style={{ fontSize: '25px' }}>Investments</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          If you are interested in investing in groundbreaking projects and
          technologies, Mining Roots provides a platform for strategic
          investments. Connect with our team to explore potential ventures.
        </h4>
      </div>
      <Card
        border="dark"
        style={{
          minHeight: '30vh',
          background: '#FAFAFA',
          borderRadius: '4rem',
        }}
      >
        <Card.Body>
          <Card.Title>
            {
              <div>
                <h2
                  style={{
                    paddingBottom: '2rem',
                    color: '#0087ff',
                    paddingTop: '2rem',
                    fontSize: '25px',
                  }}
                >
                  Say Hello Here
                </h2>
              </div>
            }
          </Card.Title>
          <Card.Text>
            <div>
              <ul>
                <li className="job-list">
                  Mail to us at{' '}
                  <a href="mailto:team@mining-roots.com">
                    team@mining-roots.com
                  </a>{' '}
                  in below-specified format
                </li>
                <li className="job-list">
                  Subject :- Partnership Opportunity (your/org name)
                </li>
                <li className="job-list">Include the following details:</li>
              </ul>
              <ol style={{ marginLeft: '2rem', paddingLeft: '2rem' }}>
                <li className="job-list">Full Name</li>
                <li className="job-list">Phone Number</li>
                <li className="job-list">LinkedIn Profile</li>
                <li className="job-list">Current Job Title</li>
                <li className="job-list">Organization/Individual Name</li>
                <li className="job-list">Website Link (if any)</li>
                <li className="job-list">Nature of Partnership Interest</li>
                <li className="job-list">
                  WeBrief Description of Your Organization or Expertise
                </li>
                <li className="job-list">
                  Why do you want to join Mining Roots, and what unique skills
                  or perspectives can you bring to our team?
                </li>
              </ol>
              <ul>
                <li className="job-list">Attach a PDF of your Portfolio</li>
              </ul>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

const ExpertFreelancing = () => {
  return (
    <div className="jobs">
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>💼</h1>
          <h2 style={{ fontSize: '25px' }}>Harness Your Expertise</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Are you a seasoned professional, a PhD holder, or an expert with
          specialized knowledge? Mining Roots offers freelancing opportunities
          on a contract basis for experts like you. Contribute your expertise to
          cutting-edge projects and be part of our network of skilled
          professionals.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🏆</h1>
          <h2 style={{ fontSize: '25px' }}>Flexible and Rewarding</h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Design your workload around your needs and commitments. Work on
          projects that pique your interest, contribute to groundbreaking
          research.
        </h4>
      </div>
      <div className="job">
        <Stack
          direction={'row'}
          spacing={3}
          alignItems="center"
          style={{
            paddingBottom: '1rem',
          }}
        >
          <h1 style={{ fontSize: '40px' }}>🚀</h1>
          <h2 style={{ fontSize: '25px' }}>
            Be a Part of Groundbreaking Initiatives
          </h2>
        </Stack>
        <h4 style={{ fontSize: '20px' }}>
          Lend your knowledge to cutting-edge projects that have the potential
          to revolutionize different industries. Make your mark on a platform
          that values your expertise and fosters innovation.
        </h4>
      </div>
      <Card
        border="dark"
        style={{
          minHeight: '30vh',
          background: '#FAFAFA',
          borderRadius: '4rem',
        }}
      >
        <Card.Body>
          <Card.Title>
            {
              <div>
                <h2
                  style={{
                    paddingBottom: '2rem',
                    color: '#0087ff',
                    paddingTop: '2rem',
                    fontSize: '25px',
                  }}
                >
                  Say Hello Here
                </h2>
              </div>
            }
          </Card.Title>
          <Card.Text>
            <div>
              <ul>
                <li className="job-list">
                  Mail to us at{' '}
                  <a href="mailto:team@mining-roots.com">
                    team@mining-roots.com
                  </a>{' '}
                  in below-specified format
                </li>
                <li className="job-list">
                  Subject :- Freelancing Opportunity (your name)
                </li>
                <li className="job-list">Include the following details:</li>
              </ul>
              <ol style={{ marginLeft: '2rem', paddingLeft: '2rem' }}>
                <li className="job-list">Full Name</li>
                <li className="job-list">Phone Number</li>
                <li className="job-list">LinkedIn Profile</li>
                <li className="job-list">Current Job Title</li>
                <li className="job-list">Area of Expertise</li>
                <li className="job-list">Years of Experience</li>
                <li className="job-list">
                  Highest Degree Earned (e.g. PhD, etc.){' '}
                </li>
                <li className="job-list">Availability and Desired Workload</li>
                <li className="job-list">
                  Why do you want to join Mining Roots, and what unique skills
                  or perspectives can you bring to our team?
                </li>
              </ol>
              <ul>
                <li className="job-list">
                  Attach a PDF of your latest Portfolio & Work Sample
                </li>
              </ul>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
