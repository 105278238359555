import React from 'react';
import { useState } from 'react';
import { blue, red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Startup from './Startup';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import SmallMediumBusiness from './SmallMediumBusiness';
import LargeEnterprise from './LargeEnterprise';
import FamilyBusiness from './FamilyBusiness';
import Student from './Student';
import CollegeUniversity from './CollegeUniversity';
import SoloFounder from './SoloFounder';
import SideHustler from './SideHustler';
import { message } from 'antd';
import '../index.css';

let RawData = { UserDescription: '', Consent: false };

export function GetData(field, value, submit) {
  RawData = { ...RawData, [field]: value };
  console.log(RawData);
}

export default function ContactUs() {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  function select(name, e, f) {
    f(e);
    GetData(name, e, false);
  }

  const options = [
    {
      value: 'Startup',
      label: 'Startup',
    },
    {
      value: 'Small/Medium Business',
      label: 'Small/Medium Business',
    },
    {
      value: 'Large Enterprise',
      label: 'Large Enterprise',
    },
    {
      value: 'Family Business',
      label: 'Family Business',
    },
    {
      value: 'Student',
      label: 'Student',
    },
    {
      value: 'College/University',
      label: 'College/University',
    },
    {
      value: 'Solo Founder',
      label: 'Solo Founder',
    },
    {
      value: 'Side Hustler',
      label: 'Side Hustler',
    },
  ];

  const areaOfInterest = [
    {
      value: 'Research & Development',
      label: 'Research & Development',
    },
    {
      value: 'Industrial Analysis',
      label: 'Industrial Analysis',
    },
    {
      value: 'Concept Building & Evaluation',
      label: 'Concept Building & Evaluation',
    },
    {
      value: 'Technological Consultancy',
      label: 'Technological Consultancy',
    },
    {
      value: 'Not Sure',
      label: 'Not Sure',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  function alertFailure(errorMessage) {
    messageApi.open({
      type: 'error',
      content: errorMessage,
    });
  }

  function alertSuccess() {
    messageApi
      .open({
        type: 'loading',
        content: 'Submitting..',
        duration: 2.5,
      })
      .then(() => message.success('Submitted Successfully', 1.5))
      .then(() => {
        window.location.reload();
      });
    console.log('RawData');
  }

  function submitForm() {
    if (RawData.UserDescription === '') {
      alertFailure('Please select an option from the dropdown');
      return;
    }
    if (RawData.UserDescription === 'Startup') {
      if (RawData.CompanyName === '' || RawData.CompanyName === undefined) {
        alertFailure('Please enter your company name');
        return;
      }
      if (RawData.DevelopmentStage === undefined) {
        alertFailure('Please select a stage of development');
        return;
      }
      if (RawData.FundingStatus === '' || RawData.FundingStatus === undefined) {
        alertFailure('Please select a funding status');
        return;
      }
      if (RawData.TargetMarket === '' || RawData.TargetMarket === undefined) {
        alertFailure('Please enter your target market');
        return;
      }
    }
    if (RawData.UserDescription === 'Small/Medium Business') {
      if (RawData.CompanyName === '' || RawData.CompanyName === undefined) {
        alertFailure('Please enter your company name');
        return;
      }
      if (RawData.Industry === '' || RawData.Industry === undefined) {
        alertFailure('Please select an industry');
        return;
      }
      if (
        RawData.NumberOfEmployees === '' ||
        RawData.NumberOfEmployees === undefined
      ) {
        alertFailure('Please enter the number of employees');
        return;
      }
    }
    if (RawData.UserDescription === 'Large Enterprise') {
      if (RawData.CompanyName === '' || RawData.CompanyName === undefined) {
        alertFailure('Please enter your company name');
        return;
      }
      if (RawData.Industry === '' || RawData.Industry === undefined) {
        alertFailure('Please select an industry');
        return;
      }
      if (RawData.Department === '' || RawData.Department === undefined) {
        alertFailure('Please enter your department');
        return;
      }
      if (RawData.ProjectScope === '' || RawData.ProjectScope === undefined) {
        alertFailure('Please enter your project scope');
        return;
      }
    }
    if (RawData.UserDescription === 'Family Business') {
      if (RawData.BusinessName === '' || RawData.BusinessName === undefined) {
        alertFailure('Please enter your business name');
        return;
      }
      if (RawData.Industry === '' || RawData.Industry === undefined) {
        alertFailure('Please select an industry');
        return;
      }
      if (
        RawData.ValueOfBusiness === '' ||
        RawData.ValueOfBusiness === undefined
      ) {
        alertFailure('Please select a value of business');
        return;
      }
      if (
        RawData.ScopeOfBusiness === '' ||
        RawData.ScopeOfBusiness === undefined
      ) {
        alertFailure('Please enter your scope of business');
        return;
      }
    }
    if (RawData.UserDescription === 'Student') {
      if (RawData.YearOfStudy === '' || RawData.YearOfStudy === undefined) {
        alertFailure('Please enter your year of study');
        return;
      }
      if (RawData.Major === '' || RawData.Major === undefined) {
        alertFailure('Please enter your major');
        return;
      }
      if (RawData.Institute === '' || RawData.Institute === undefined) {
        alertFailure('Please enter your institute');
        return;
      }
    }
    if (RawData.UserDescription === 'College/University') {
      if (RawData.Institute === '' || RawData.Institute === undefined) {
        alertFailure('Please enter your institute');
        return;
      }
      if (RawData.Department === '' || RawData.Department === undefined) {
        alertFailure('Please enter your department');
        return;
      }
      if (
        RawData.ResearchFocusArea === '' ||
        RawData.ResearchFocusArea === undefined
      ) {
        alertFailure('Please enter your research focus area');
        return;
      }
    }
    if (RawData.UserDescription === 'Solo Founder') {
      if (
        RawData.BusinessConcept === '' ||
        RawData.BusinessConcept === undefined
      ) {
        alertFailure('Please enter your business concept');
        return;
      }
      if (
        RawData.CurrentProgress === '' ||
        RawData.CurrentProgress === undefined
      ) {
        alertFailure('Please enter your current progress');
        return;
      }
    }
    if (RawData.UserDescription === 'Side Hustler') {
      if (RawData.ProjectIdea === '' || RawData.ProjectIdea === undefined) {
        alertFailure('Please enter your project idea');
        return;
      }
      if (RawData.CurrentStage === '' || RawData.CurrentStage === undefined) {
        alertFailure('Please select your current stage');
        return;
      }
    }
    if (RawData.AreaOfInterest === '' || RawData.AreaOfInterest === undefined) {
      alertFailure('Please select an area of interest');
      return;
    }
    if (
      RawData.ProjectDescription === '' ||
      RawData.ProjectDescription === undefined
    ) {
      alertFailure('Please enter your project description');
      return;
    }
    if (RawData.DesiredOutcome === '' || RawData.DesiredOutcome === undefined) {
      alertFailure('Please enter your desired outcome');
      return;
    }
    if (RawData.FirstName === '' || RawData.FirstName === undefined) {
      alertFailure('Please enter your first name');
      return;
    }
    if (RawData.LastName === '' || RawData.LastName === undefined) {
      alertFailure('Please enter your last name');
      return;
    }
    if (RawData.EmailAddress === '' || RawData.EmailAddress === undefined) {
      alertFailure('Please enter your email');
      return;
    }
    if (RawData.Consent === false) {
      alertFailure('Please check the consent checkbox');
      return;
    }

    const DataToSubmit = new FormData();
    for (const item in RawData) {
      DataToSubmit.append(item, RawData[item]);
    }
    alertSuccess();
    fetch(
      'https://script.google.com/macros/s/AKfycbxrSWkvA2FSqjm0n_hvQJjSJwtSbU21T0P-Dl-W9LisJn58L8aAMvBiC2hRU0Oz-isJ/exec',
      {
        method: 'POST',
        body: DataToSubmit,
      }
    );
  }

  return (
    <div className="contact">
      {contextHolder}
      <h4 style={{ color: '#fff', fontSize: '28px', paddingBottom: '1rem' }}>
        Welcome to Mining Roots!
      </h4>
      <h4 style={{ color: '#fff', fontSize: '20px', paddingBottom: '1rem' }}>
        We're excited to hear from you and learn how we can help you unlock the
        potential of your needs. To ensure we connect you with the right
        resources, select the category that best describes you.
      </h4>
      <h4 style={{ color: '#fff', fontSize: '20px', paddingBottom: '2rem' }}>
        Or if it suits you better, email us at{' '}
        <a href="mailto:team@mining-roots.com" style={{ color: '#BC14FE' }}>
          team@mining-roots.com
        </a>
        .
      </h4>
      <div style={{ paddingBottom: '1vh' }}>
        <UserDescription
          options={options}
          onSelect={(e) => select('UserDescription', e, setSelectedOption)}
        />
      </div>
      {selectedOption === 'Startup' && <Startup />}
      {selectedOption === 'Small/Medium Business' && <SmallMediumBusiness />}
      {selectedOption === 'Large Enterprise' && <LargeEnterprise />}
      {selectedOption === 'Family Business' && <FamilyBusiness />}
      {selectedOption === 'Student' && <Student />}
      {selectedOption === 'College/University' && <CollegeUniversity />}
      {selectedOption === 'Solo Founder' && <SoloFounder />}
      {selectedOption === 'Side Hustler' && <SideHustler />}
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <AreaOfInterest
          options={areaOfInterest}
          onSelect={(e) =>
            select('AreaOfInterest', e, setSelectedAreaOfInterest)
          }
        />

        {selectedAreaOfInterest === 'Other' && <OtherAreaOfInterest />}
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ProjectDescription />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <DesiredOutcome />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <Grid container columns={2}>
          <Grid item xs={1}>
            <Item style={{ textAlign: 'center' }}>
              <FirstName />
            </Item>
          </Grid>
          <Grid item xs={1}>
            <Item style={{ textAlign: 'center' }}>
              <LastName />
            </Item>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <EmailAddress />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <PhoneNumber />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <AdditionalInformation />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <ConsentCheckbox />
      </div>
      <div
        style={{
          justifyContent: 'right',
          display: 'flex',
          alignItems: 'end',
          paddingTop: '10px',
        }}
      >
        <Button
          variant="contained"
          type="submit"
          style={{
            background: '#F6FFEC',
            width: '100%',
            color: '#389E0E',
            border: '1px solid',
            borderColor: '#389E0E',
          }}
          onClick={() => submitForm()}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#39FF14',
            '--TextField-brandBorderHoverColor': '#FFFFFF',
            '--TextField-brandBorderFocusedColor': '#1F51FF',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    },
  });

const UserDescription = ({ options, onSelect }) => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="UserDescription"
            select
            label="What best describes you?"
            variant="outlined"
            onChange={(e) => onSelect(e.target.value)}
            // color="secondary"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </Box>
    </div>
  );
};

const AreaOfInterest = ({ options, onSelect }) => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="area-of-interest"
            select
            label="Area of Interest"
            variant="outlined"
            onChange={(e) => onSelect(e.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </Box>
    </div>
  );
};

const OtherAreaOfInterest = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="other-area-of-interest"
              label="Other Area of Interest"
              variant="outlined"
              onChange={(e) => GetData('AreaOfInterest', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const ProjectDescription = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="project-description"
              label="Briefly Describe Your Current Pain Point & Project"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) =>
                GetData('ProjectDescription', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const DesiredOutcome = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="project-description"
              label="What is your desired outcome or goal"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) => GetData('DesiredOutcome', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const FirstName = () => {
  const outerTheme = useTheme();
  return (
    <div style={{ paddingRight: '0.5rem' }}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="first-name"
              label="First Name"
              variant="outlined"
              onChange={(e) => GetData('FirstName', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const LastName = () => {
  const outerTheme = useTheme();
  return (
    <div style={{ paddingLeft: '0.5rem' }}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="last-name"
              label="Last Name"
              variant="outlined"
              onChange={(e) => GetData('LastName', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const EmailAddress = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="email-address"
              label="Email Address"
              variant="outlined"
              onChange={(e) => GetData('EmailAddress', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const PhoneNumber = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              id="phone-number"
              label="Phone Number (Optional)"
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+</InputAdornment>
                ),
                style: { color: '#fff' },
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 12);
              }}
              onChange={(e) => GetData('PhoneNumber', e.target.value, false)}
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const AdditionalInformation = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              id="additional-information"
              label="Is there anything else you'd like us to know? (Optional)"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) =>
                GetData('AdditionalInformation', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const ConsentCheckbox = () => {
  const [isConsentGiven, setConsent] = useState(false);

  const handleConsentChange = () => {
    setConsent(!isConsentGiven);
    GetData('Consent', !isConsentGiven, false);
  };

  return (
    <div className="consent">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isConsentGiven}
              onChange={handleConsentChange}
              sx={{
                color: red[800],
                '&.Mui-checked': {
                  color: blue[600],
                },
              }}
            />
          }
          label="By submitting this form, you agree to allow Mining Roots to
              contact you regarding your inquiry *"
          componentsProps={{ typography: { color: 'white' } }}
        />
      </FormGroup>
    </div>
  );
};
