import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { GetData } from './ContactUs';
import { customTheme } from './ContactUs';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function SoloFounder() {
  const [selectedOption, setSelectedOption] = useState('');
  const progressOptions = [
    {
      value: 'Idea Stage',
      label: 'Idea Stage',
    },
    {
      value: 'Prototype Stage',
      label: 'Prototype Stage',
    },
    {
      value: 'Beta Testing',
      label: 'Beta Testing',
    },
    {
      value: 'Pre-Launch Stage',
      label: 'Pre-Launch Stage',
    },
    {
      value: 'Product Launch',
      label: 'Product Launch',
    },
    {
      value: 'Early Growth Stage',
      label: 'Early Growth Stage',
    },
    {
      value: 'Scaling',
      label: 'Scaling',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];
  return (
    <div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <BusinessConcept />
      </div>
      <div style={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
        <CurrentProgress
          options={progressOptions}
          onSelect={setSelectedOption}
        />
        {selectedOption === 'Other' && <OtherProgress />}
      </div>
    </div>
  );
}

const BusinessConcept = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="business-concept"
              label="Business Concept"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) =>
                GetData('BusinessConcept', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};

const CurrentProgress = ({ options, onSelect }) => {
  const outerTheme = useTheme();
  function select(name, e, f) {
    f(e);
    GetData(name, e, false);
  }
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            required
            id="current-progress"
            select
            label="Current Progress"
            variant="outlined"
            onChange={(e) =>
              select('CurrentProgress', e.target.value, onSelect)
            }
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </Box>
    </div>
  );
};

const OtherProgress = () => {
  const outerTheme = useTheme();
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
              required
              id="other-progress"
              label="Other Progress"
              variant="outlined"
              onChange={(e) =>
                GetData('CurrentProgress', e.target.value, false)
              }
            />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
};
