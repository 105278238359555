import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image1 from '../images/Who Are You Side Hustler.webp';
import { ContactCard } from '../ContactDrawer';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../index.css';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function ForSide() {
  return (
    <div>
      <ImageAndText />
      <div
        style={{
          paddingBottom: '5rem',
          paddingRight: '10px',
          paddingLeft: '10px',
        }}
      >
        <StartupSwiper />
        <ContactCard />
      </div>
    </div>
  );
}

function ImageAndText() {
  return (
    <div>
      <Row>
        <Col sm={4}>
          <div style={{ textAlign: 'center' }}>
            <LazyLoadImage
              src={image1}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={8}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{ paddingBottom: '20px', fontSize: '27px' }}
            >
              <span id="who-are-you-first-text">Side Hustle Hero?</span> Power
              Up Your Passion Project
            </h1>
            <h2
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              Juggling day jobs & dreams? We're your secret weapon. Unleash your
              side hustle power with actionable insights & the right tools.
            </h2>
            <h2
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              Juggling day jobs & dreams? We're your secret weapon. Unleash your
              side hustle power with actionable insights & the right tools.
            </h2>
          </div>
        </Col>
      </Row>
      <h2
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        Juggling day jobs & dreams? We're your secret weapon. Unleash your side
        hustle power with actionable insights & the right tools.
      </h2>
    </div>
  );
}

function StartupSwiper() {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
          type: 'progressbar',
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1025: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '80vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🧪
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Research & Development
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">Customized R&D Solutions</li>
                      <li className="list-2">
                        Tool Selection & Implementation
                      </li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '80vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🌐
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Market Insights
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">Data Collection & Assessment</li>
                      <li className="list-2">SWOT Analysis</li>
                      <li className="list-2">Financial Insights</li>
                      <li className="list-2">Market Research & Analysis</li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '80vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🚀
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Operational Excellence & Innovation
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">
                        Operational Efficiency Enhancement
                      </li>
                      <li className="list-2">Data-Driven Decision Making</li>
                      <li className="list-2">Rapid Prototyping & Testing</li>
                      <li className="list-2">Detailed Cost Estimation</li>
                      <li className="list-2">
                        Evaluation & Selection of Optimal Technologies
                      </li>
                      <li className="list-2">Efficiency Optimization</li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
