import React, { useState, Suspense } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Card } from 'antd';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Carousel } from 'antd';
import image1 from '../../images/Research _ Development Where Creativity Meets Innovation.webp';
import image2 from '../../images/Benefits of partnering with Mining Roots for Research _ Development.webp';
import '../../index.css';
import ValuesDrawer from './ValuesDrawer';
import MobileSideCard from './MobileSideCard';
import Benefits from './Benefits';

export default function Data() {
  return (
    <div>
      <div style={{ paddingBottom: '1rem' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartOne />
        </Suspense>
      </div>
      <div
        className="mobile"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div
        className="tablet"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <PartTwo />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartThree />
        </Suspense>
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartFour />
        </Suspense>
      </div>
    </div>
  );
}

function PartOne() {
  localStorage.setItem('topImage', image1);
  const [topImage, setTopImage] = useState(
    localStorage.getItem('topImage') || image1
  );
  console.log(setTopImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <div style={{ textAlign: 'center' }}>
            <LazyLoadImage
              src={topImage}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={8}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{ paddingBottom: '20px', fontSize: '35px' }}
            >
              Where <span style={{ color: '#BC13FE' }}>Creativity</span> Meets{' '}
              <span style={{ color: '#42A047' }}>Innovation</span>
            </h1>
            <h3
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, research and design are not just departments,
              they are the lifeblood of our company. We believe that by
              fostering a culture of continuous exploration and experimentation,
              we can unlock the potential of your business and push the
              boundaries of what's possible.
            </h3>
            <h3
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, research and design are not just departments,
              they are the lifeblood of our company. We believe that by
              fostering a culture of continuous exploration and experimentation,
              we can unlock the potential of your business and push the
              boundaries of what's possible.
            </h3>
          </div>
        </Col>
      </Row>
      <h3
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
        }}
      >
        At Mining Roots, research and design are not just departments, they are
        the lifeblood of our company. We believe that by fostering a culture of
        continuous exploration and experimentation, we can unlock the potential
        of your business and push the boundaries of what's possible.
      </h3>
    </div>
  );
}

function PartTwo() {
  return (
    <div>
      <h2
        style={{
          paddingBottom: '2rem',
          fontSize: '27px',
          color: '#0087FF',
        }}
      >
        <span style={{ fontWeight: '500', fontSize: '27px' }}>R&D</span>{' '}
        Offerings
      </h2>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🔬</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Research Services
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Scientific Research Services</h4>
            </li>
            <li className="list">
              <h4>Technical Research</h4>
            </li>
            <li className="list">
              <h4>Data Analysis and Modelling</h4>
            </li>
            <li className="list">
              <h4>Prototyping and Testing</h4>
            </li>
            <li className="list">
              <h4>Customized R&D Solutions</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🚀</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Technology Integration
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Tech R&D</h4>
            </li>
            <li className="list">
              <h4>Tool Selection and Implementation</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>📊</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Project Management
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>End-to-End Project Management</h4>
            </li>
            <li className="list">
              <h4>Resource and Budget Optimization</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>📚</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Training Support
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul style={{ fontSize: '1.1rem' }}>
            <li className="list">
              <h4>Methodology Workshops</h4>
            </li>
            <li className="list">
              <h4>Continuous Support</h4>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
}

function PartThree() {
  localStorage.setItem('bottomImage', image2);
  const [bottomImage, setBottomImage] = useState(
    localStorage.getItem('bottomImage') || image2
  );
  console.log(setBottomImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <LazyLoadImage
            src={bottomImage}
            alt=""
            style={{ width: '100%', height: '100%', paddingBottom: '1rem' }}
          />
        </Col>
        <Col sm={8}>
          <h2 style={{ fontSize: '27px', color: '#0087FF' }}>
            Benefits of Partnering with Us
          </h2>
          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Benefits />
            <ValuesDrawer />
          </div>
        </Col>
      </Row>
    </div>
  );
}

// const contentStyle = {
//   color: '#000000',
//   lineHeight: '40px',
//   textAlign: 'center',
//   background: '#E8E9EB',
// };

function PartFour() {
  return (
    <div style={{ paddingTop: '2rem' }}>
      <h4 style={{ textAlign: 'center', fontSize: '20px' }}>
        These offerings collectively empower clients to conduct cutting-edge
        research, develop innovative technologies, make data-driven decisions,
        and bring new ideas to fruition. The goal is to enhance efficiency,
        drive technological advancements, and foster innovation across various
        industries.
      </h4>
    </div>
  );
}
