import React, { useState, Suspense } from 'react';
import { Card } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Carousel } from 'antd';
import image1 from '../../images/Concept Building _ Evaluation Transforming Visionary Ideas into Tangible Realities.webp';
import image2 from '../../images/Benefits of Mining Roots_ Concept Building _ Evaluation.webp';
import '../../index.css';
import ValuesDrawer from './ValuesDrawer';
import MobileSideCard from './MobileSideCard';
import Benefits from './Benefits';

export default function Data() {
  return (
    <div>
      <div style={{ paddingBottom: '1rem' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartOne />
        </Suspense>
      </div>
      <div
        className="mobile"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div
        className="tablet"
        style={{ paddingBottom: '1rem', textAlign: 'center' }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <MobileSideCard />
        </Suspense>
      </div>
      <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <PartTwo />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartThree />
        </Suspense>
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <Suspense fallback={<div>Loading</div>}>
          <PartFour />
        </Suspense>
      </div>
    </div>
  );
}

function PartOne() {
  localStorage.setItem('topImage', image1);
  const [topImage, setTopImage] = useState(
    localStorage.getItem('topImage') || image1
  );
  console.log(setTopImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <div style={{ textAlign: 'center' }}>
            <LazyLoadImage
              src={topImage}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={8}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{
                paddingBottom: '20px',
                fontSize: '35px',
              }}
            >
              Transforming{' '}
              <span style={{ color: '#BC13FE' }}>Visionary Ideas</span> into{' '}
              <span style={{ color: '#42A047' }}>Tangible Realities</span>
            </h1>
            <h3
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, we believe that groundbreaking ideas can emerge
              from any field, not just the scientific or technological realm.
              Our unique expertise lies in concept building and evaluation,
              where we bridge the gap between scientific and non-technological
              fields to help you turn your visionary ideas into actionable
              realities.
            </h3>
            <h3
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              At Mining Roots, we believe that groundbreaking ideas can emerge
              from any field, not just the scientific or technological realm.
              Our unique expertise lies in concept building and evaluation,
              where we bridge the gap between scientific and non-technological
              fields to help you turn your visionary ideas into actionable
              realities.
            </h3>
          </div>
        </Col>
      </Row>
      <h3
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
        }}
      >
        At Mining Roots, we believe that groundbreaking ideas can emerge from
        any field, not just the scientific or technological realm. Our unique
        expertise lies in concept building and evaluation, where we bridge the
        gap between scientific and non-technological fields to help you turn
        your visionary ideas into actionable realities.
      </h3>
    </div>
  );
}

function PartTwo() {
  return (
    <div>
      <h2
        style={{
          paddingBottom: '2rem',
          fontSize: '27px',
          color: '#0087FF',
        }}
      >
        <span style={{ fontWeight: '500', fontSize: '27px' }}>
          Concept Building & Evaluation
        </span>{' '}
        Offerings
      </h2>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🔧</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Design Services
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Process Optimization</h4>
            </li>
            <li className="list">
              <h4>Interdisciplinary Expertise</h4>
            </li>
            <li className="list">
              <h4>Customized Solutions</h4>
            </li>
            <li className="list">
              <h4>Efficiency Meets Aesthetics</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🤖</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Prototyping and Testing
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Creative Ideation</h4>
            </li>
            <li className="list">
              <h4>Product Design</h4>
            </li>
            <li className="list">
              <h4>Rapid Prototyping and Testing</h4>
            </li>
            <li className="list">
              <h4>User-Centered Design</h4>
            </li>
            <li className="list">
              <h4>Data-Driven Decision-Making</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🤔</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Feasibility Analysis
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Technical Feasibility Assessment</h4>
            </li>
            <li className="list">
              <h4>Financial Viability Analysis</h4>
            </li>
            <li className="list">
              <h4>Market Research and Analysis</h4>
            </li>
            <li className="list">
              <h4>Innovation Integration</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>💰</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Cost-Benefit Analysis
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Detailed Cost Estimation</h4>
            </li>
            <li className="list">
              <h4>Potential Revenue and Profit Projections</h4>
            </li>
            <li className="list">
              <h4>Sensitivity Analysis</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>🚨</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Risk Assessment
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Identification and Analysis of Risks</h4>
            </li>
            <li className="list">
              <h4>Mitigation Strategy Development</h4>
            </li>
            <li className="list">
              <h4>Contingency Planning</h4>
            </li>
          </ul>
        </Card>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Card
          id="offerings"
          title={
            <div>
              <h1 style={{ fontSize: '35px' }}>📊</h1>
              <h3 style={{ fontSize: '25px', color: '#0087ff' }}>
                Impact Assessment
              </h3>
            </div>
          }
          bordered={false}
          style={{
            marginTop: '15px',
            background: '#FAFAFA',
            borderRadius: '2rem',
          }}
        >
          <ul>
            <li className="list">
              <h4>Social Impact Analysis</h4>
            </li>
            <li className="list">
              <h4>Environmental Impact Analysis</h4>
            </li>
            <li className="list">
              <h4>Ethical Considerations</h4>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
}

function PartThree() {
  localStorage.setItem('bottomImage', image2);
  const [bottomImage, setBottomImage] = useState(
    localStorage.getItem('bottomImage') || image2
  );
  console.log(setBottomImage);
  return (
    <div>
      <Row>
        <Col sm={4}>
          <LazyLoadImage
            src={bottomImage}
            alt=""
            style={{ width: '100%', height: '100%', paddingBottom: '1rem' }}
          />
        </Col>
        <Col sm={8}>
          <h2 style={{ fontSize: '27px', color: '#0087FF' }}>
            Benefits of Choosing Us
          </h2>
          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Benefits />
            <ValuesDrawer />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function PartFour() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
      <h4 style={{ textAlign: 'center', fontSize: '20px' }}>
        These offerings empower clients to transform creative ideas into viable
        and practical realities. The goal is to provide guidance and expertise
        in the concept development process, from the initial brainstorming phase
        to the assessment of feasibility and market potential. By offering these
        services, Mining Roots helps clients bridge the gap between innovative
        ideas and their practical implementation.
      </h4>
    </div>
  );
}
