import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import ResearchAndDev from './offerings/ResearchAndDev/ResearchAndDev';
import IndustrialAnalysis from './offerings/IndustrialAnalysis/IndustrialAnalysis';
import ConceptBuilding from './offerings/ConceptBuilding/ConceptBuilding';
import TechnoConsult from './offerings/TechnologicalConsultancy/TechnoConsult';
import WhoAreYou from './Who Are You/WhoAreYou';
import Navbar from './NewNavBar';
import MNavbar from './NewNavBarMobile';
import Career from './Career/Career';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';

export default function App() {
  return (
    <BrowserRouter>
      <div className="desktop">
        <Navbar />
      </div>
      <div className="tablet">
        <Navbar />
      </div>
      <div className="mobile">
        <MNavbar />
      </div>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="home" element={<Home />}></Route>
        <Route path="career" element={<Career />}></Route>
        <Route
          path="offerings/research-and-development"
          element={<ResearchAndDev />}
        ></Route>
        <Route
          path="offerings/industry-analysis"
          element={<IndustrialAnalysis />}
        ></Route>
        <Route
          path="offerings/concept-building-and-evaluation"
          element={<ConceptBuilding />}
        ></Route>
        <Route
          path="offerings/technology-consultancy"
          element={<TechnoConsult />}
        ></Route>
        <Route path="who-are-you" element={<WhoAreYou />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
