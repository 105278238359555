import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image1 from '../images/Who Are You Startup.webp';
import { ContactCard } from '../ContactDrawer';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../index.css';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function ForStartups() {
  return (
    <div>
      <ImageAndText />
      <div
        style={{
          paddingBottom: '5rem',
          paddingRight: '10px',
          paddingLeft: '10px',
        }}
      >
        <StartupSwiper />
        <ContactCard />
      </div>
    </div>
  );
}

function ImageAndText() {
  return (
    <div>
      <Row>
        <Col sm={4}>
          <div style={{ textAlign: 'center' }}>
            <LazyLoadImage
              src={image1}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </Col>
        <Col
          sm={8}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1
              className="maintitle"
              style={{ paddingBottom: '20px', fontSize: '27px' }}
            >
              <span id="who-are-you-first-text">Startup Launchpad:</span> Blast
              Off with Tailored Solutions
            </h1>
            <h2
              className="desktop"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              Congratulations on taking the exciting leap into the world of
              startups! We underst& the unique challenges you face - rapid
              growth, tight budgets, & the need to constantly innovate. That's
              why we've crafted a specialized suite of offerings designed to
              empower your journey from fledgling venture to market leader.
            </h2>
            <h2
              className="mobile"
              style={{
                textAlign: 'justify',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontSize: '20px',
              }}
            >
              Congratulations on taking the exciting leap into the world of
              startups! We underst& the unique challenges you face - rapid
              growth, tight budgets, & the need to constantly innovate. That's
              why we've crafted a specialized suite of offerings designed to
              empower your journey from fledgling venture to market leader.
            </h2>
          </div>
        </Col>
      </Row>
      <h2
        className="tablet"
        style={{
          textAlign: 'justify',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontSize: '20px',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        Congratulations on taking the exciting leap into the world of startups!
        We underst& the unique challenges you face - rapid growth, tight
        budgets, & the need to constantly innovate. That's why we've crafted a
        specialized suite of offerings designed to empower your journey from
        fledgling venture to market leader.
      </h2>
    </div>
  );
}

function StartupSwiper() {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
          type: 'progressbar',
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1025: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div style={{ paddingBottom: '3rem' }}>
            <Card
              border="dark"
              style={{
                minHeight: '60vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🏆
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Data Excellence
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">Data Analysis & Modeling</li>
                      <li className="list-2">Customized R&D Solutions</li>
                      <li className="list-2">Tech R&D</li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '60vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        📆
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Project Management & Strategy
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">Data Collection & Assessment</li>
                      <li className="list-2">Competitive L&scape Analysis</li>
                      <li className="list-2">Market Segmentation & Analysis</li>
                      <li className="list-2">
                        Threat Analysis (New Entrants, Substitutes)
                      </li>
                      <li className="list-2">SWOT Analysis</li>
                      <li className="list-2">Financial Benchmarking</li>
                      <li className="list-2">Cost & Profitability Analysis</li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '60vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🛡️
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Risk Mitigation & Future Planning
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">
                        Risk Mitigation & Contingency Planning
                      </li>
                      <li className="list-2">Emerging Technology Analysis</li>
                      <li className="list-2">
                        Social & Economic Trend Analysis
                      </li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '60vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🏅
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Operational Excellence
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">
                        Operational Efficiency Enhancement
                      </li>
                      <li className="list-2">Data-Driven Decision Making</li>
                      <li className="list-2">
                        Technical Feasibility Assessment
                      </li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '60vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        💻
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Technology Infrastructure & Security
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">
                        Comprehensive Technology Infrastructure Audit
                      </li>
                      <li className="list-2">
                        Development of a Technology Roadmap
                      </li>
                      <li className="list-2">
                        Evaluation & Selection of Optimal Technologies
                      </li>
                      <li className="list-2">Security & Risk Management</li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              border="dark"
              style={{
                minHeight: '60vh',
                cursor: 'pointer',
                background: '#FAFAFA',
                borderRadius: '4rem',
              }}
            >
              <Card.Body>
                <Card.Title>
                  {
                    <div>
                      <h1
                        style={{
                          fontSize: '60px',
                          paddingBottom: '1.5rem',
                          paddingTop: '2rem',
                        }}
                      >
                        🚀
                      </h1>
                      <h2
                        style={{
                          paddingBottom: '1.5rem',
                          color: '#0087ff',
                          fontSize: '21px',
                        }}
                      >
                        Efficiency & Future-Ready Solutions
                      </h2>
                    </div>
                  }
                </Card.Title>
                <Card.Text>
                  <div>
                    <ul style={{ textAlign: 'start' }}>
                      <li className="list-2">Efficiency Optimization</li>
                      <li className="list-2">
                        Staying Ahead with Emerging Technology Trends
                      </li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
