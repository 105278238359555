import React, { useState, Suspense } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import './index.css';
import RD from './images/What We Do Research _ Development.webp';
import IA from './images/What We Do Industrial Analysis.webp';
import TC from './images/What We Do Technological Consultancy.webp';
import CB from './images/What We Do Concept Building _ Evaluation.webp';
import Contact from './images/contact us 1.webp';
import { Drawer } from 'antd';
import ContactUs from './contact/ContactUs';

export default function HomeLayout() {
  const [open, setOpen] = useState(false);
  localStorage.setItem('contactImage', Contact);
  const [contactImage, setContactImage] = useState(
    localStorage.getItem('contactImage') || Contact
  );
  localStorage.setItem('CBImage', CB);
  const [CBImage, setCBImage] = useState(localStorage.getItem('CBImage') || CB);
  localStorage.setItem('IAImage', IA);
  const [IAImage, setIAImage] = useState(localStorage.getItem('IAImage') || IA);
  localStorage.setItem('RDImage', RD);
  const [RDImage, setRDImage] = useState(localStorage.getItem('RDImage') || RD);
  localStorage.setItem('TCImage', TC);
  const [TCImage, setTCImage] = useState(localStorage.getItem('TCImage') || TC);
  console.log(setContactImage, setCBImage, setIAImage, setRDImage, setTCImage);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ background: '#FAFAFA' }}>
      <Row>
        <Col style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw' }}>
          <Suspense fallback={<div>Loading</div>}>
            <Card
              border="dark"
              style={{
                //   maxWidth: '32rem',
                width: '61.67vw',
                height: '20vh',
                //   minWidth: '34rem',
                // cursor: 'pointer',
                color: '#000000',
                marginTop: '2.5vh',
                marginRight: '0.5vw',
                marginBottom: '0',
                borderRadius: '2rem',
                boxShadow: '7px 7px 10px rgba(0, 0, 0, 0.25)',
              }}
            >
              <Card.Body>
                <Card.Title>
                  <h1
                    style={{
                      fontSize: '1.7vw',
                      color: '#0087ff',
                      marginBottom: '5px',
                    }}
                  >
                    Unlocking Efficiency & Innovation
                  </h1>
                </Card.Title>
                <Card.Text>
                  <p style={{ fontSize: '1.7vh', textAlign: 'justify' }}>
                    Welcome to Mining Roots, your trusted partner for
                    comprehensive scientific and technological solutions
                    designed to optimize your operations and propel your
                    business forward. We specialize in research, design,
                    industrial analysis, concept building, and evaluations
                    across various industries. As pioneers in our field, we
                    provide innovative solutions to enhance efficiency,
                    security, and technological advancements.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Suspense>
          <Row>
            <Col style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw' }}>
              <Link to={'/who-are-you'}>
                <Suspense fallback={<div>Loading</div>}>
                  <Card
                    border="dark"
                    style={{
                      //   maxWidth: '32rem',
                      height: '20vh',
                      //   minWidth: '10rem',
                      width: '29.9vw',
                      cursor: 'pointer',
                      color: '#000000',
                      marginTop: '2.51vh',
                      marginBottom: '0',
                      // background: '#feb7ad',
                      background: 'linear-gradient(to right, #feb7ad, #fecfc8)',
                      borderRadius: '2rem',
                      boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>
                        <h1 style={{ fontSize: '3.5vh', paddingTop: '5.5vh' }}>
                          Who Are You?
                        </h1>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Suspense>
              </Link>
              <div>
                <Suspense fallback={<div>Loading</div>}>
                  <Card
                    onClick={showDrawer}
                    border="dark"
                    style={{
                      //   maxWidth: '32rem',
                      height: '42.7vh',
                      //   minWidth: '10rem',
                      width: '29.9vw',
                      cursor: 'pointer',
                      color: '#4F4F4F',
                      marginTop: '2.51vh',
                      marginBottom: '0',
                      borderRadius: '2rem',
                      boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Card.Img
                      loading="lazy"
                      src={contactImage}
                      alt="Card image"
                      style={{ height: '42.7vh', borderRadius: '2rem' }}
                    />
                    <Card.ImgOverlay>
                      <Card.Title>
                        <h1
                          style={{
                            paddingBottom: '2vh',
                            fontSize: '3.5vh',
                            color: '#FFFFFF',
                          }}
                        >
                          Contact Us
                        </h1>
                      </Card.Title>
                      <Card.Text>
                        <h4
                          style={{
                            fontSize: '2.5vh',
                            paddingTop: '2vh',
                            color: '#FFFFFF',
                          }}
                        >
                          Get a free consultation
                        </h4>
                        <h4
                          style={{
                            fontSize: '2.5vh',
                            paddingTop: '3vh',
                            color: '#FFFFFF',
                          }}
                        >
                          Based in Mumbai, India
                        </h4>
                        <h4
                          style={{
                            fontSize: '2.5vh',
                            paddingTop: '1vh',
                            color: '#FFFFFF',
                          }}
                        >
                          team@mining-roots.com
                        </h4>
                        <h4
                          style={{
                            fontSize: '2.5vh',
                            paddingTop: '5vh',
                            color: '#FFFFFF',
                          }}
                        >
                          <i>
                            <u>Join The Revolution</u>
                          </i>
                        </h4>
                      </Card.Text>
                    </Card.ImgOverlay>
                  </Card>
                </Suspense>
                <Drawer
                  title="Contact Us Form"
                  placement="right"
                  onClose={onClose}
                  open={open}
                  size="large"
                  id="contact"
                >
                  <ContactUs />
                </Drawer>
              </div>
            </Col>
            <Col style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw' }}>
              <Suspense fallback={<div>Loading</div>}>
                <Card
                  border="dark"
                  style={{
                    //   maxWidth: '32rem',
                    height: '65.1vh',
                    //   minWidth: '10rem',
                    width: '29.9vw',
                    // cursor: 'pointer',
                    color: '#000000',
                    marginTop: '2.51vh',
                    marginBottom: '0',
                    //   background: '#cf9fff',
                    background: 'linear-gradient(to bottom, #cf9fff, #ddbbff)',
                    borderRadius: '2rem',
                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      <h1 style={{ paddingBottom: '2vh', fontSize: '1.7vw' }}>
                        Why Choose Us?
                      </h1>
                    </Card.Title>
                    <Card.Text>
                      <h4 style={{ fontSize: '1.4vw' }}>
                        <span style={{ fontSize: '3.5vh' }}>✨</span> Innovation
                        & Creativity
                      </h4>
                      <p style={{ fontSize: '1vw', marginBottom: '5px' }}>
                        We craft groundbreaking solutions with diverse expertise
                      </p>
                      <h4 style={{ fontSize: '1.4vw' }}>
                        <span style={{ fontSize: '3.5vh' }}>⏱️</span> Efficiency
                        & Cost Reduction
                      </h4>
                      <p style={{ fontSize: '1vw', marginBottom: '5px' }}>
                        Streamline processes and maximize your bottom line
                      </p>
                      <h4 style={{ fontSize: '1.4vw' }}>
                        <span style={{ fontSize: '3.5vh' }}>🛡️</span> Security &
                        Trust
                      </h4>
                      <p style={{ fontSize: '1vw', marginBottom: '5px' }}>
                        Safeguard your data and operations with our secure
                        solutions
                      </p>
                      <h4 style={{ fontSize: '1.4vw' }}>
                        <span style={{ fontSize: '3.5vh' }}>🌐</span>{' '}
                        Technological Advancements
                      </h4>
                      <p style={{ fontSize: '1vw', marginBottom: '5px' }}>
                        Stay ahead of the curve with leading-edge technologies
                      </p>
                      <h4 style={{ fontSize: '1.4vw' }}>
                        <span style={{ fontSize: '3.5vh' }}>🤝</span>{' '}
                        Client-Centric Approach
                      </h4>
                      <p style={{ fontSize: '1vw', marginBottom: '5px' }}>
                        We collaborate closely to achieve shared success
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Suspense>
            </Col>
          </Row>
        </Col>
        <Col style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw' }}>
          <Link to={'/offerings/concept-building-and-evaluation'}>
            <Suspense fallback={<div>Loading</div>}>
              <Card
                border="dark"
                style={{
                  //   maxWidth: '32rem',
                  width: '30.8vw',
                  height: '20vh',
                  //   minWidth: '17rem',
                  cursor: 'pointer',
                  color: '#000000',
                  marginTop: '2.51vh',
                  marginBottom: '0',
                  borderRadius: '2rem',
                  boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                }}
              >
                <Card.Img
                  loading="lazy"
                  src={CBImage}
                  alt="Card image"
                  style={{ height: '20vh', borderRadius: '2rem' }}
                />
                <Card.ImgOverlay>
                  <Card.Title>
                    <h1 style={{ fontSize: '4vh' }}>🛠️</h1>
                    <h1 style={{ fontSize: '1.7vw', marginBottom: '0' }}>
                      Concept
                    </h1>
                    <h1 style={{ fontSize: '1.7vw' }}>Building</h1>
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
            </Suspense>
          </Link>

          <Link to={'/offerings/industry-analysis'}>
            <Suspense fallback={<div>Loading</div>}>
              <Card
                border="dark"
                style={{
                  //   maxWidth: '32rem',
                  height: '20vh',
                  //   minWidth: '17rem',
                  width: '30.83vw',
                  cursor: 'pointer',
                  color: '#000000',
                  marginTop: '2.51vh',
                  marginBottom: '0',
                  borderRadius: '2rem',
                  boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                }}
              >
                <Card.Img
                  loading="lazy"
                  src={IAImage}
                  alt="Card image"
                  style={{ height: '20vh', borderRadius: '2rem' }}
                />
                <Card.ImgOverlay>
                  <Card.Title>
                    <h1 style={{ fontSize: '4vh' }}>🏭</h1>
                    <h1 style={{ fontSize: '1.7vw', marginBottom: '0' }}>
                      Industrial
                    </h1>
                    <h1 style={{ fontSize: '1.7vw' }}>Analysis</h1>
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
            </Suspense>
          </Link>
          <Link to={'/offerings/research-and-development'}>
            <Suspense fallback={<div>Loading</div>}>
              <Card
                border="dark"
                style={{
                  //   maxWidth: '32rem',
                  width: '30.83vw',
                  height: '20vh',
                  //   minWidth: '17rem',
                  cursor: 'pointer',
                  color: '#000000',
                  marginTop: '2.51vh',
                  marginBottom: '0',
                  borderRadius: '2rem',
                  boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                }}
              >
                <Card.Img
                  loading="lazy"
                  src={RDImage}
                  alt="Card image"
                  style={{ height: '20vh', borderRadius: '2rem' }}
                />
                <Card.ImgOverlay>
                  <Card.Title>
                    <h1 style={{ fontSize: '4vh' }}>🚀</h1>
                    <h1 style={{ fontSize: '1.7vw', marginBottom: '0' }}>
                      Research &
                    </h1>
                    <h1 style={{ fontSize: '1.7vw' }}>Development</h1>
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
            </Suspense>
          </Link>
          <Link to={'/offerings/technology-consultancy'}>
            <Suspense fallback={<div>Loading</div>}>
              <Card
                border="dark"
                style={{
                  //   maxWidth: '32rem',
                  height: '20vh',
                  //   minWidth: '17rem',
                  width: '30.83vw',
                  cursor: 'pointer',
                  color: '#000000',
                  marginTop: '2.51vh',
                  marginBottom: '0',
                  borderRadius: '2rem',
                  boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.25)',
                }}
              >
                <Card.Img
                  loading="lazy"
                  src={TCImage}
                  alt="Card image"
                  style={{ height: '20vh', borderRadius: '2rem' }}
                />
                <Card.ImgOverlay>
                  <Card.Title>
                    <h1 style={{ fontSize: '4vh' }}>💡</h1>
                    <h1 style={{ fontSize: '1.7vw', marginBottom: '0' }}>
                      Technological
                    </h1>
                    <h1 style={{ fontSize: '1.7vw' }}>Consultancy</h1>
                  </Card.Title>
                  {/* <Card.Text>Stay ahead of the competition</Card.Text> */}
                </Card.ImgOverlay>
              </Card>
            </Suspense>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
